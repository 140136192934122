import { Button } from "primereact/button";
import React from "react";

export const AddressCard = ({ endereco, onDelete, id }) => {
  const gridStyle = {
    backgroundColor: "#323232",
    margin: "10px",
    color: "white",
    padding: "10px",
    borderRadius: "10px",
    boxShadow: "4px 5px 5px rgba(0,0,0,0.8)",
    border: "1px solid white",
  };

  return (
    <div className="p-grid" style={gridStyle}>
      <div className="p-grid p-dir-col p-col-11" style={{ padding: "10px" }}>
        <div>
          {endereco.endereco}, {endereco.numero}
        </div>
        <div>{endereco.bairro}</div>
        <div>{endereco.complemento}</div>
      </div>
      <div className="p-col-1" style={{ alignSelf: "center" }}>
        <Button
          style={{
            backgroundColor: "orange",
            border: "0",
            borderRadius: "25px",
            boxShadow: "4px 5px 5px rgba(0,0,0,0.8)",
          }}
          icon="pi pi-trash"
          onClick={() => onDelete(id)}
        />
      </div>
    </div>
  );
};
