import React from "react";

export const Header = (props) => {
  const { config, loja } = props;

  const nameEmpresaStyle = {
    position: "absolute",
    border: "1px #c8c8c8 solid",
    backgroundColor: config.theme.labelEmpresaBK,
    opacity: "0.7",
    top: "12rem",
    width: "100%",
    fontWeight: "bold",
    fontSize: "18px",
    color: config.theme.labelEmpresaColor,
    alignContent: "center",
    textAlign: "center",
  };

  const logoEmpresaStyle = {
    position: "absolute",
    border: "1px #c8c8c8 solid",
    top: "6rem",
    left: "calc((100% / 2) - 2.5rem)",
    width: "5rem",
    alignContent: "center",
    borderRadius: "50%",
  };

  const ImgFundo = () => {
    return (
      <img
        style={{ width: "100%", height: "15rem", marginTop: "1rem" }}
        alt="Card"
        src={
          loja
            ? `./static/${config.empresa.tenentID}/fundo_loja.jpg`
            : `./static/${config.empresa.tenentID}/fundo.jpg`
        }
      />
    );
  };

  const ImgLogo = () => {
    if (config.theme.nologo) {
      return null;
    }

    return (
      <img
        style={logoEmpresaStyle}
        alt="logo"
        src={`./static/${config.empresa.tenentID}/logo.jpg`}
      />
    );
  };

  const LabelEmpresa = () => {
    if (!config.empresa.nome || config.empresa.nome === "") {
      return null;
    }

    return (
      <div style={nameEmpresaStyle} className="p-text">
        {config.empresa.nome}
      </div>
    );
  };

  return (
    <div
      id="g_header"
      style={{ minHeight: "15rem", marginTop: "50px", marginBottom: "-5px" }}
    >
      <ImgFundo />
      <ImgLogo />
      <LabelEmpresa />
    </div>
  );
};
