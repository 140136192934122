import React from "react";

export const CartSummaryItem = ({ title, value, config, style }) => {
  const cartSummaryStyle = {
    ...style,
    backgroundColor: config.theme.primary,
  };

  return (
    <div className="p-col" style={{ padding: "0" }}>
      <div className="p-grid p-justify-between" style={cartSummaryStyle}>
        <div
          className="p-col-7"
          style={{
            marginLeft: "0.5rem",
            textAlign: "right",
            fontWeight: "bold",
          }}
        >
          {title}
        </div>
        <div
          className="p-col-4 p-col-align-center"
          style={{
            textAlign: "right",
            marginRight: "2vh",
            fontWeight: "bold",
          }}
        >
          R$ {value.toFixed(2)}
        </div>
      </div>
    </div>
  );
};
