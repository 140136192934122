import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import "primeicons/primeicons.css";
import "primereact/resources/primereact.min.css";
import "./theme.css";
import "primeflex/primeflex.css";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

process.env.NODE_ENV === "production" &&
  Sentry.init({
    dsn: "https://3c9e0809c9c1461180ae8d9e58a5479c@o1277530.ingest.sentry.io/6475045",
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.2,
  });

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorkerRegistration.unregister();
