import axios from "axios";

async function carregaProdutos(tenentID) {
  try {
    let resp = await axios.get(`/produtoapi?$filter=empresa eq '${tenentID}'`);
    return resp;
  } catch (e) {
    const { response } = e;
    console.log(e);

    if (response) {
      return response;
    } else {
      return { statusText: "Erro de conexão", status: "400" };
    }
  }
}

async function carregaCategorias(tenentID, tipo) {
  let json = {
    tenentID,
    tipo,
  };

  try {
    let resp = await axios.post("/produtoapiservice/getCategorias", json);
    return resp;
  } catch (e) {
    console.log(e);
    const { response } = e;

    if (response) {
      return response;
    } else {
      return { statusText: "Erro de conexão", status: "400" };
    }
  }
}

export { carregaProdutos, carregaCategorias };
