import React from "react";
import { ProgressSpinner } from "primereact/progressspinner";

export const Loading = ({ ativa, slug }) => {
  const styles = {
    main: {
      position: "relative",
      backgroundColor: "#4A1D16",
      borderRadius: "20px",
      margin: "20px",
      marginTop: "15%",
      filter: "drop-shadow(2px 4px 6px black)",
    },
    loading: {
      color: "white",
      backgroundColor: "rgba(0,0,0,0.5)",
      height: "auto",
      fontWeight: "bold",
      fontSize: "1.4rem",
      textAlign: "center",
      marginTop: "30px",
      marginBottom: "30px",
    },
  };

  return (
    <div style={styles.main}>
      <img
        src="../static/fundo_genuine_mini.png"
        alt="fundo"
        width="100%"
        height="100%"
        style={{ opacity: "0.5", marginTop: "20px" }}
      ></img>
      <div style={styles.loading}>
        {ativa ? (
          <div>Carregando Dados... Aguarde</div>
        ) : (
          <div>
            {`Empresa "${slug}" não encontrada! Certifique-se de que digitou o link corretamente!`}{" "}
          </div>
        )}
      </div>
      {ativa && (
        <ProgressSpinner
          style={{ marginLeft: "35%", height: "50px" }}
          strokeWidth="8"
          fanimationDuration=".5s"
        />
      )}
      <div style={{ height: "30px" }}></div>
    </div>
  );
};
