import React, { useContext, useEffect, useState, useRef } from "react";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { Input } from "../../Components/Input";
import { Separator } from "../../Components/Separator";
import { useHistory } from "react-router";
import { MainContext } from "../../Contexts/MainContext";
import { registerUser } from "../../services/clienteAPIService";
import { Toast } from "primereact/toast";
import { InputMask } from "primereact/inputmask";

export const LoginView = () => {
  const toast = useRef(null);
  const history = useHistory();
  const { slug, defineUser, user } = useContext(MainContext);
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");

  useEffect(() => {
    if (user) {
      history.push(`/${slug}`);
    }
  }, [user, slug, history]);

  function onRetorna() {
    history.push(`/${slug}`);
  }

  async function onLogin() {
    let payload = {
      email,
      telefone,
      cliente: {},
    };

    if (telefone === "") {
      toast.current.show({
        severity: "error",
        summary: "Telefone Inválido!",
        detail: "",
      });
      return;
    }

    let resp = await registerUser(payload);

    if (resp.status === 200) {
      defineUser(resp.data);
      history.goBack();
    } else {
      if (resp.status === 50) {
        history.push(`/${slug}/register?telefone=${telefone}&email=${email}`);
        return;
      }

      toast.current.show({
        severity: "error",
        summary: "Problema de Acesso!",
        detail: resp.data.error.message,
      });
    }
  }

  return (
    <>
      <Toast ref={toast} position="bottom-left"></Toast>
      <div
        className="p-shadow-12"
        style={{
          width: "100%",
          height: "100vh",
          padding: "5px",
          paddingTop: "20vh",
          filter: "drop-shadow(2px 4px 6px black)",
        }}
      >
        <Card
          style={{
            backgroundColor: "orange",
            margin: "10%",
            borderRadius: "15px",
          }}
          className=" p-shadow-12"
        >
          <div className="p-grid p-dir-col">
            <div className="p-col">
              <Input
                id="username"
                label="E-mail"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </div>
            <div className="p-col">
              <span className="p-mb-4">
                <label
                  style={{ color: "#323232", alignSelf: "center" }}
                  htmlFor="telefone"
                >
                  Telefone
                </label>
                <InputMask
                  type="mask"
                  style={{
                    borderRadius: "10px",
                    backgroundColor: "cornsilk",
                    color: "black",
                    width: "100%",
                    filter: "drop-shadow(1px 1px 4px rgba(0,0,0,0.5))",
                    border: 0,
                    marginTop: "7px",
                    marginBottom: "5px",
                  }}
                  mask="(99) 99999-9999"
                  id="telefone"
                  label="Telefone"
                  onChange={(e) => setTelefone(e.target.value)}
                  value={telefone}
                />
              </span>
            </div>
            <Separator />
            <div className="p-col p-mb-2">
              <Button
                onClick={onLogin}
                style={{
                  width: "100%",
                  borderRadius: "10px",
                  boxShadow: "1px 4px 17px 0.4px rgba(0,0,0,0.75)",
                  backgroundColor: "#323232",
                  color: "orange",
                  border: "0",
                  justifyContent: "center",
                }}
              >
                ENTRAR
              </Button>
              <Button
                onClick={onRetorna}
                style={{
                  width: "100%",
                  borderRadius: "10px",
                  boxShadow: "1px 4px 17px 0.4px rgba(0,0,0,0.75)",
                  backgroundColor: "#323232",
                  color: "orange",
                  border: "0",
                  marginTop: "20px",
                  justifyContent: "center",
                }}
              >
                VOLTAR AO MENU
              </Button>
            </div>
          </div>
        </Card>
      </div>
    </>
  );
};
