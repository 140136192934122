import React, { useContext, useState } from "react";
import { Sidebar } from "primereact/sidebar";
import { Dropdown } from "primereact/dropdown";
import { useForm } from "react-hook-form";
import { Input } from "../../Components/Input";
import { Button } from "primereact/button";
import { MainContext } from "../../Contexts/MainContext";

export const AddressForm = (props) => {
  const { visible, onClose, onConfirm } = props;
  const { register, handleSubmit, errors } = useForm();
  const { config, opt } = useContext(MainContext);
  const [bairro, setBairro] = useState();
  const [cidade, setCidade] = useState();

  const onSubmit = (data) => {
    if (bairro) {
      let newData = { ...data, bairro: bairro.descricao };
      onConfirm(newData);
    } else if (cidade) {
      let newData = { ...data, cidade: cidade.cidade };
      onConfirm(newData);
    } else {
      onConfirm(data);
    }
  };

  const sideBarStyle = {
    backgroundColor: "orange",
    padding: "20px",
    paddingTop: "40px",
    borderTopLeftRadius: "25px",
    borderTopRightRadius: "25px",
    height: "28rem", //25rem
  };

  function onBairroChange(e) {
    setBairro(e.value);
  }

  const ComboBairro = () => {
    return (
      <Dropdown
        style={{
          borderRadius: "10px",
          backgroundColor: "cornsilk",
          color: "black",
          width: "100%",
          filter: "drop-shadow(1px 1px 4px rgba(0,0,0,0.5))",
          zIndex: "20",
          marginTop: "6px",
        }}
        placeholder="Selecione o Bairro"
        optionLabel="descricao"
        options={config?.bairros}
        name="bairro"
        value={bairro}
        onChange={onBairroChange}
      />
    );
  };

  const ComboCidade = () => {
    return (
      <>
        <label
          style={{
            color: "#323232",
            alignSelf: "center",
          }}
          htmlFor={bairro}
        >
          Cidade
        </label>
        <Dropdown
          style={{
            borderRadius: "10px",
            backgroundColor: "cornsilk",
            color: "black",
            width: "100%",
            filter: "drop-shadow(1px 1px 4px rgba(0,0,0,0.5))",
            zIndex: "20",
            marginTop: "6px",
          }}
          placeholder="Selecione a Cidade"
          optionLabel="cidade"
          options={opt.cidades}
          name="cidade"
          value={cidade}
          onChange={(e) => setCidade(e.value)}
        />
      </>
    );
  };

  const InputBairro = () => {
    return (
      <Input
        name="bairro"
        innerRef={register({ required: true })}
        error={errors.bairros}
      />
    );
  };

  return (
    <Sidebar
      className="p-sidebar-md"
      visible={visible}
      showCloseIcon={true}
      position="bottom"
      height="4.4rem"
      style={sideBarStyle}
      onHide={onClose}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="p-grid p-dir-col">
          <Input
            name="endereco"
            label="Endereço"
            innerRef={register({ required: true })}
            error={errors.endereco}
          />
          <div className="p-grid">
            <div className="p-col-12">{opt.cidades && <ComboCidade />}</div>
          </div>
          <div className="p-grid">
            <div className="p-col-3">
              <Input
                className="p-col"
                name="numero"
                label="Número"
                innerRef={register}
              />
            </div>
            <div className="p-col-9">
              <span>
                <label
                  style={{
                    color: "#323232",
                    alignSelf: "center",
                  }}
                  htmlFor={bairro}
                >
                  Bairro
                </label>
                {config.bairros.length === 0 ? (
                  <InputBairro />
                ) : (
                  <ComboBairro />
                )}
              </span>
            </div>
          </div>
          <Input name="complemento" label="Complemento" innerRef={register} />
          <div className="p-grid p-mt-2">
            <div className="p-col-fixed" style={{ width: "6rem" }}>
              <Button
                className="p-button-danger"
                label="Cancelar"
                onClick={onClose}
              />
            </div>
            <div className="p-col">
              <Button
                label="Adicionar"
                className="p-button-success"
                style={{
                  width: "100%",
                }}
              />
            </div>
          </div>
        </div>
      </form>
      <div
        style={{
          border: "1px solid black",
          padding: "4px",
          color: "black",
          borderRadius: "10px",
          textAlign: "center",
        }}
      >
        Se o seu bairro não estiver na listagem de entrega, entre em contato com
        o estabelecimento.
      </div>
    </Sidebar>
  );
};
