import axios from "axios";

async function carregaAuth() {
  try {
    let resp = await axios.post(`/empresaapiservice/getauth`, { role: "user" });

    if (resp.data.value.length === 0)
      return { statusText: "Empresa não habilitada", status: 400 };

    return resp;
  } catch (e) {
    const { response } = e;
    console.log(e);

    if (response) {
      return response;
    } else {
      return { statusText: "Erro de conexão", status: "400" };
    }
  }
}

async function carregaEmpresa(slug) {
  try {
    let resp = await axios.get(`/empresaapi?$filter=empresa eq '${slug}'`);

    if (resp.data.value.length === 0)
      return { statusText: `Empresa ${slug} não encontrada`, status: 400 };

    return resp;
  } catch (e) {
    console.log(e);
    const { response } = e;

    if (response) {
      return response;
    } else {
      return { statusText: "Erro de conexão", status: "400" };
    }
  }
}

async function carregaDesativados(idweb) {
  try {
    let resp = await axios.post(`/empresaapiservice/getDesativados`, {
      ID: idweb,
    });

    return resp;
  } catch (e) {
    console.log(e);
    const { response } = e;

    if (response) {
      return response;
    } else {
      return { statusText: "Erro de conexão", status: "400" };
    }
  }
}

async function carregaDesativadosQuery({ queryKey }) {
  console.log("Lendo desativados");
  try {
    let resp = await axios.post(`/empresaapiservice/getDesativados`, {
      ID: queryKey[1],
    });

    return resp;
  } catch (e) {
    console.log(e);
    const { response } = e;

    if (response) {
      return response;
    } else {
      return { statusText: "Erro de conexão", status: "400" };
    }
  }
}

async function carregaOutro({ queryKey }) {
  try {
    let resp = await axios.post(`/empresaapiservice/getOutro`, {
      ID: queryKey[1],
    });

    return resp;
  } catch (e) {
    console.log(e);
    const { response } = e;

    if (response) {
      return response;
    } else {
      return { statusText: "Erro de conexão", status: "400" };
    }
  }
}

export {
  carregaEmpresa,
  carregaAuth,
  carregaDesativados,
  carregaOutro,
  carregaDesativadosQuery,
};
