import React from "react";

export const Separator = ({color, height}) => {
  return (
    <div style={{ backgroundColor: color, height: height, padding: "1vh" }}>
      <div
        className="p-col-align-center"
        style={{ backgroundColor: "rgba(255,255,255,0.7)", height: "3px" }}
      ></div>
    </div>
  );
};
