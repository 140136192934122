import { BrowserRouter, Switch, Route } from "react-router-dom";
import "./App.css";
import MainProvider from "./Contexts/MainContext";
import { Home } from "./Pages/Home";
import { ProductView } from "./Pages/Product";
import { PizzaView } from "./Pages/Pizza";
import { CartView } from "./Pages/Cart";
import { LoginView } from "./Pages/Login";
import { RegisterView } from "./Pages/Register";
import { StepView } from "./Pages/Step";
import RegisterOnly from "./Pages/RegisterOnly";
import axios from "axios";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

let BASE_URL = "";
//let BASE_URL = "http://localhost:2003/genuine/";

if (process.env.NODE_ENV === "development") {
  BASE_URL = "http://192.168.100.166:2003/genuine/";
} else {
  BASE_URL = "https://app.genuinesistemas.com.br:2442/genuine/";
}

axios.defaults.baseURL = BASE_URL;
const queryClient = new QueryClient();

const Homepage = () => {
  window.location.href = "https://www.genuinesistemas.com.br";
  return null;
};

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <MainProvider>
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={Homepage} />
            <Route exact path="/:slug" component={Home} />
            <Route path="/:slug/product" component={ProductView} />
            <Route path="/:slug/pizza" component={PizzaView} />
            <Route path="/:slug/cart" component={CartView} />
            <Route path="/:slug/login" component={LoginView} />
            <Route path="/:slug/register" component={RegisterView} />
            <Route path="/:slug/step" component={StepView} />
            <Route path="/:slug/registeronly" component={RegisterOnly} />
          </Switch>
        </BrowserRouter>
      </MainProvider>
    </QueryClientProvider>
  );
}

export default App;
