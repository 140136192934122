import React, { useContext, memo } from "react";
import { useHistory } from "react-router-dom";
import { MainContext } from "../../Contexts/MainContext";
import moment from "moment";
import "moment/locale/pt-br";

const ProductCard = (props) => {
  const history = useHistory();
  const { filterList, setEditProduct, slug, cardapio } =
    useContext(MainContext);
  const { config } = props;
  const nopic = config.theme.nopic === true && props.item.imagem === "";

  const imgStyle = {
    filter: "drop-shadow(1px 1px 4px black)",
    border: "2px solid orange",
    borderRadius: "20px",
  };

  const cardCardapioStyle = {
    width: "100%",
    borderRadius: "10px 10px 0px 0",
    padding: "10px",
    backgroundColor: "#323232",
    position: "relative",
    border: "1px solid orange",
  };

  const priceCardapioStyle = {
    width: "100%",
    borderBottomRightRadius: "10px",
    top: "0",
    left: "0",
    borderBottomLeftRadius: "10px",
    backgroundColor: "orange",
    color: "black",
    height: "30px",
    textAlign: "left",
    paddingTop: "2px",
    paddingLeft: "15px",
    fontWeight: "bold",
    marginBottom: "1.3rem",
  };

  const blockStyle = {
    width: "100%",
    backgroundColor: "red",
    color: "white",
    minHeigh: "40px",
    textAlign: "center",
    padding: "5px",
    fontSize: "14px",
  };

  function onSelectProduct(block) {
    let newProduct = Object.assign(
      {},
      filterList.find((obs) => obs.id === props.index)
    );
    setEditProduct({ type: "insert", produto: newProduct, block });

    if (newProduct.pizza) {
      history.push(`/${slug}/pizza`);
    } else {
      history.push(`/${slug}/product`);
    }
  }

  const ProductMode = () => {
    const detail = props.item.detalhe !== "";
    let render = true;
    let mostrar = true;
    let block = null;
    const disponibilidade = props.item.json
      ? JSON.parse(props.item.json)
      : null;
    let tempo = disponibilidade?.tempo;
    let valordiff = disponibilidade?.valordiff;

    const valor = valordiff ? valordiff : props.item.preco;

    if (tempo) {
      const current = moment();

      let _diaSemana = current.format("ddd").replace("sáb", "sab");
      let _abertura = moment(tempo[`${_diaSemana}horaini`]).locale("pt-br");
      let _fechamento = moment(tempo[`${_diaSemana}horafim`]).locale("pt-br");

      let abertura = moment({
        hour: _abertura.hour(),
        minute: _abertura.minute(),
      });
      let fechamento = moment({
        hour: _fechamento.hour(),
        minute: _fechamento.minute(),
      });

      if (abertura.isSameOrAfter(fechamento)) {
        if (current.hour() >= 0 && current.hour() <= fechamento.hour()) {
          abertura.add("-1", "day");
        } else {
          fechamento.add("1", "day");
        }
      }

      if (
        current.isSameOrAfter(abertura) &&
        current.isSameOrBefore(fechamento)
      ) {
      } else {
        block = `Produto disponível das (${abertura.format(
          "HH:mm"
        )}hrs às ${fechamento.format("HH:mm")}hrs).`;

        mostrar = !tempo.naomostra;
      }

      if (_abertura.isSame(_fechamento)) {
        console.log("entrei no same");
        block =
          tempo.mensagem === ""
            ? "Produto não disponivel hoje!"
            : tempo.mensagem;
        mostrar = !tempo.naomostra;
      }

      render = tempo[_diaSemana];
    }

    if (!render || !mostrar) {
      return <div></div>;
    }

    return (
      <>
        <div
          style={cardCardapioStyle}
          className="p-grid p-justify-between"
          onClick={() => onSelectProduct(block)}
        >
          <div className="p-col-8" style={{ padding: "0.1rem" }}>
            <div className="p-grid p-dir-col p-justify-between">
              <div
                className="p-col"
                style={{
                  color: "white",
                  fontSize: "0.8rem",
                  whiteSpace: "break-spaces",
                }}
              >
                {props.item.descricao.replace("(", "\r\n(")}
                {detail && (
                  <div
                    style={{ border: "1px solid orange", marginTop: "4px" }}
                  ></div>
                )}
                <div
                  style={{
                    fontSize: "0.7rem",
                    color: "wheat",
                    marginTop: "5px",
                  }}
                >
                  {props.item.detalhe.replace("(", "\r\n(")}
                </div>
              </div>
            </div>
          </div>
          <div className="p-col-align-center p-align-end">
            <img
              style={imgStyle}
              height={100}
              width={100}
              alt="--"
              src={
                props.item.imagem
                  ? `../static/${config.empresa.tenentID}/${props.item.imagem}`
                  : props.item.pizza
                  ? "../static/pizza.jpg"
                  : "../static/sem_foto.png"
              }
            />
          </div>
        </div>
        {block && (
          <div className="p-col" style={blockStyle}>
            {block}
          </div>
        )}
        <div className="p-col" style={priceCardapioStyle}>
          {props.item.pizza ? "A Partir de " : ""} R$ {valor.toFixed(2)}
        </div>
      </>
    );
  };

  const CardapioMode = () => {
    const detail = props.item.detalhe !== "";
    const disponibilidade = props.item.json
      ? JSON.parse(props.item.json)
      : null;
    let valordiff = disponibilidade?.valordiff;

    const valor = valordiff ? valordiff : props.item.preco;

    return (
      <>
        <div
          id="g_produto_main"
          style={cardCardapioStyle}
          className="p-grid p-justify-between"
          onClick={onSelectProduct}
        >
          <div
            className={nopic ? "p-col-12" : "p-col-8"}
            style={{ padding: "0.1rem" }}
          >
            <div className="p-grid p-dir-col p-justify-between">
              <div
                className="p-col"
                style={{
                  color: "white",
                  fontSize: "0.8rem",
                  whiteSpace: "break-spaces",
                }}
              >
                {props.item.descricao.replace("(", "\r\n(")}
                {detail && (
                  <div
                    style={{ border: "1px solid orange", marginTop: "4px" }}
                  ></div>
                )}
                <div
                  style={{
                    fontSize: "0.7rem",
                    color: "wheat",
                    marginTop: "5px",
                  }}
                >
                  {props.item.detalhe.replace("(", "\r\n(")}
                </div>
              </div>
            </div>
          </div>
          {!nopic && (
            <div className="p-align-end">
              <img
                style={imgStyle}
                height={100}
                width={100}
                alt="--"
                src={
                  props.item.imagem
                    ? `../static/${config.empresa.tenentID}/${props.item.imagem}`
                    : props.item.pizza
                    ? "../static/pizza.jpg"
                    : "../static/sem_foto.png"
                }
              />
            </div>
          )}
        </div>
        <div className="p-col" style={priceCardapioStyle}>
          {props.item.pizza ? "A Partir de " : ""} R$ {valor.toFixed(2)}
        </div>
      </>
    );
  };

  return cardapio ? <CardapioMode /> : <ProductMode />;
};

export default memo(ProductCard);
