import React from "react";

export const InfoProduct = (props) => {
  const { config, item, opcional, tipo, valorMeia } = props;
  const json = item.json ? JSON.parse(item.json) : null;
  const preco = json?.valordiff ? json.valordiff : item.preco;

  const styles = {
    imgStyle: {
      border: `2px solid ${config.theme.secondary}`,
      marginTop: "15px",
      marginLeft: "5px",
      borderRadius: "15px",
    },
    descStyle: {
      border: `2px solid ${config.theme.secondary}`,
      height: "120px",
      marginTop: "22px",
      width: "95%",
      color: config.theme.fontColor,
      padding: "4px",
      borderTopLeftRadius: "10px",
      borderTopRightRadius: "10px",
      fontSize: "12px",
      overflow: "overlay",
      display: "grid",
      alignContent: "center",
    },
    priceStyle: {
      backgroundColor: config.theme.secondary,
      width: "95%",
      textAlign: "center",
      fontWeight: "bold",
      height: "30px",
      borderBottomLeftRadius: "10px",
      borderBottomRightRadius: "10px",
      overflow: "overlay",
    },
  };

  return (
    <div className="p-grid">
      <div className="p-col-fixed">
        <img
          className="p-shadow-7"
          alt="img"
          src={
            item.imagem
              ? `../static/${config.empresa.tenentID}/${item.imagem}`
              : "../static/sem_foto.png"
          }
          height={150}
          width={150}
          style={styles.imgStyle}
        />
      </div>
      <div className="p-col">
        <div className="p-grid p-dir-col">
          <div style={styles.descStyle} className="p-shadow-7 p-text-center">
            {item.detalhe}
          </div>
          <div className="p-shadow-7" style={styles.priceStyle}>
            {tipo === "INTEIRA"
              ? `R$ ${preco.toFixed(2)} `
              : `R$ ${valorMeia.toFixed(2)} `}
            {opcional > 0 ? ` + (R$ ${opcional.toFixed(2)})` : ""}
          </div>
        </div>
      </div>
    </div>
  );
};
