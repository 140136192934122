import React, { useContext, useState, useEffect, useRef } from "react";
import { Input } from "../../Components/Input";
import { InputMask } from "primereact/inputmask";
import { ToolBar } from "../Home/ToolBar";
import { Button } from "primereact/button";
import { Footer } from "../../Components/Footer";
import { MainContext } from "../../Contexts/MainContext";
import { AddressCard } from "./AddressCard";
import { AddressForm } from "./AddressForm";
import { useForm } from "react-hook-form";
import { registerUser } from "../../services/clienteAPIService";
import { Toast } from "primereact/toast";
import "./register.css";
import { useHistory, useLocation } from "react-router";

export const RegisterView = () => {
  const toast = useRef(null);
  const telref = useRef(null);
  const { user, config, defineUser, slug, cartItems } = useContext(MainContext);
  const [enderecos, setEnderecos] = useState([]);
  const [addressVisible, setAddressVisible] = useState(false);
  const [bairroList, setBairroList] = useState([]);
  const [allowed, setAllowed] = useState([]);
  const { register, handleSubmit, setValue } = useForm({
    defaultValues: {
      email: "daniel@gmail.com",
    },
  });
  const history = useHistory();
  const [valid, setValid] = useState(true);
  const location = useLocation();
  const fone = user
    ? user.telefone
    : new URLSearchParams(location.search).get("telefone");

  if (!config?.theme || !config?.bairros) {
    const _destination = localStorage.getItem("_slugSearch");
    history.push(`/${_destination}`);
  }

  const gridStyle = {
    backgroundColor: "#323232",
    width: "100%",
    color: "orange",
    fontWeight: "bold",
    filter: "drop-shadow(2px 4px 6px black)",
    marginLeft: "0",
    marginTop: "0",
  };

  useEffect(() => {
    let _temp = [];

    config?.bairros.forEach((el) => _temp.push(el.descricao.toLowerCase()));

    setAllowed(_temp);

    if (user) {
      setBairroList(user.enderecos);
    }
  }, [config, user]);

  useEffect(() => {
    setValue("email", new URLSearchParams(location.search).get("email"));

    setValue("telefone", fone);
    register({ name: "telefone" });
  }, [location.search, setValue, register, fone]);

  useEffect(() => {
    if (user && user.enderecos) setEnderecos(user.enderecos);
  }, [user]);

  useEffect(() => {
    if (enderecos.length === 0) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [enderecos]);

  const onSubmit = async (data) => {
    if (!data.telefone) {
      setValue("telefone", fone);
    }

    let newData = { ...data, enderecos: enderecos, id: user?.id };
    let payload = {
      email: data.email,
      telefone: data.telefone ? data.telefone : fone,
      cliente: newData,
    };

    if (data.nome === "") {
      toast.current.show({
        severity: "error",
        summary: "Nome do cliente inválido!",
        detail: "",
      });
      return;
    }

    if (payload.telefone === "") {
      toast.current.show({
        severity: "error",
        summary: "Telefone inválido!",
        detail: "",
      });
      return;
    }

    if (payload.telefone.substring(5, 6) !== "9") {
      toast.current.show({
        severity: "error",
        summary: "Telefone inválido!",
        detail: "",
      });
      return;
    }

    let resp = await registerUser(payload);

    if (resp.status === 200) {
      defineUser(resp.data);

      if (!user) {
        history.push(`/${slug}/cart`);
      } else {
        toast.current.show({
          severity: "success",
          summary: "Cliente Atualizado!",
          detail: "Dados atualizados com sucesso!",
        });

        if (cartItems && cartItems.length > 0) {
          history.push(`/${slug}/cart`);
        }
      }
    } else {
      if (resp.data?.error) {
        toast.current.show({
          severity: "error",
          summary: "Erro",
          detail: resp.data.error.message,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Erro Conexão com o Servidor",
          detail: "Erro, verifique sua internet!",
        });
      }
    }
  };

  function addressConfirm(data) {
    setEnderecos([...enderecos, data]);
    setBairroList((old) => [...old, data]);
    setAddressVisible(false);
  }

  function addressClose(e) {
    if (e) {
      e.preventDefault();
    }
    setAddressVisible(false);
  }

  function addressDelete(index) {
    let newData = enderecos.filter((_, i) => index !== i);
    setEnderecos(newData);
    setBairroList(newData);
  }

  const AddressList = () => {
    let _lista;

    if (allowed.length > 0) {
      _lista = bairroList?.filter((ed) =>
        allowed.includes(ed.bairro?.toLowerCase())
      );
    } else {
      _lista = bairroList;
    }

    return _lista?.map((end, index) => (
      <AddressCard
        key={index}
        id={index}
        endereco={end}
        onDelete={addressDelete}
      />
    ));
  };

  if (!config?.theme) {
    return <div></div>;
  }

  return (
    <>
      <Toast ref={toast} position="bottom-left"></Toast>
      <AddressForm
        visible={addressVisible}
        onConfirm={addressConfirm}
        onClose={addressClose}
      />
      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <ToolBar back title="Registro" />
        <div
          style={{
            padding: "20px",
            marginTop: "4rem",
            backgroundColor: config?.theme.secondary,
          }}
        >
          <Input
            style={{ marginTop: "20px" }}
            name="nome"
            label="Nome"
            innerRef={register}
            value={user?.nome}
          />
          <span>
            <label
              style={{ color: "#323232", alignSelf: "center" }}
              htmlFor="telefone"
            >
              Telefone
            </label>
            <InputMask
              autofocus
              type="mask"
              ref={telref}
              style={{
                borderRadius: "10px",
                backgroundColor: "cornsilk",
                color: "black",
                width: "100%",
                filter: "drop-shadow(1px 1px 4px rgba(0,0,0,0.5))",
                border: 0,
                marginTop: "7px",
                marginBottom: "5px",
              }}
              mask="(99) 99999-9999"
              name="telefone"
              label="Telefone"
              onChange={(e) => setValue("telefone", e.value)}
              value={fone}
            />
          </span>
          <Input
            style={{ marginTop: "30px" }}
            type="email"
            name="email"
            label="E-mail"
            innerRef={register}
            value={user?.email}
          />
        </div>
        <div className="p-grid" style={gridStyle}>
          <div className="p-col">Endereços</div>
          <Button
            label="Adicionar Endereço"
            onClick={(e) => {
              e.preventDefault();
              setAddressVisible(true);
            }}
            icon="pi pi-plus-circle"
            className="p-col-align-center"
            style={{
              marginRight: "5px",
              height: "1.8rem",
              backgroundColor: "orange",
              border: 0,
            }}
          />
        </div>
        <AddressList />
        <Footer
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "10px",
            flexDirection: "column",
            height: "auto",
          }}
        >
          <Button
            disabled={valid}
            className="p-button-success"
            label={user ? "Atualizar" : "Registrar"}
            style={{ marginBottom: "4px" }}
          />
          {user && (
            <Button
              disabled={valid}
              className="p-button-cancel"
              label={"Deslogar"}
              style={{ backgroundColor: "red", color: "white" }}
              onClick={(e) => {
                console.log("clicando no deslohar");
                e.preventDefault();
                defineUser(null);
                history.push(`/${slug}`);
              }}
            />
          )}
        </Footer>
      </form>
    </>
  );
};
