import React, { useContext, useState, useEffect } from "react";
import { ToolBar } from "../Home/ToolBar";
import { MainContext } from "../../Contexts/MainContext";
import { InfoProduct } from "./InfoProduct";
import { ComposicaoCard } from "./ComposicaoCard";
import { Footer } from "../../Components/Footer";
import { ComplementoCard } from "./ComplementoCard";
import { ObservacaoCard } from "./ObservacaoCard";
import "./Custom.css";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { useHistory } from "react-router-dom";
import { RadioButton } from "primereact/radiobutton";

export const ProductView = () => {
  const history = useHistory();
  const { addItemCart, editProduct, updateItemCart, cardapio, desativados } =
    useContext(MainContext);
  const {
    produto,
    type,
    index,
    itemMeia,
    quantidade: qtdItem,
    block,
  } = editProduct;
  const { config, isOpen } = useContext(MainContext);
  const [totalOpcional, setTotalOpcional] = useState(0);
  const [quantidade, setQuantidade] = useState(type === "edit" ? qtdItem : 1);
  const [total, setTotal] = useState(0);
  const [complementos, setComplementos] = useState(produto?.complemento);
  const [composicoes, setComposicoes] = useState(produto?.composicao);
  const [observacao, setObservacao] = useState("");
  const [valid, setValid] = useState(false);
  const [meia, setMeia] = useState(0);
  const [value, setValue] = useState("INTEIRA");
  const [semEstoque, setSemEstoque] = useState(false);
  const json = produto?.json ? JSON.parse(produto?.json) : null;
  const preco = json?.valordiff ? json?.valordiff : produto?.preco;

  const blockStyle = {
    width: "100%",
    backgroundColor: "red",
    color: "white",
    minHeigh: "40px",
    textAlign: "center",
    padding: "5px",
    fontSize: "14px",
    borderRadius: "5px",
  };

  const styles = {
    main: {
      backgroundColor: "#323232",
      height: "fit-content",
      padding: "5px",
      marginTop: "5rem",
    },
    radioGroup: {
      display: "flex",
      flexDirection: "column",
      fontColor: "white",
      color: "white",
      fontWeight: "bold",
      padding: "0.6rem 1rem",
    },
    labelPorcao: {
      height: "20px",
      backgroundColor: "orange",
      margin: "10px 0 0 5px",
      width: "30%",
      paddingLeft: "5px",
      borderTopRightRadius: "10px",
    },
    valorTotal: {
      border: "2px solid orange",
      textAlign: "center",
      color: "white",
      marginTop: "7px",
      marginBottom: "7px",
      fontWeight: "bold",
      borderRadius: "10px",
    },
    comboBorda: {
      marginLeft: "5px",
      marginRight: "5px",
      background: "#323232",
      border: `2px solid ${config?.theme.secondary}`,
      color: "black",
    },
  };

  if (!produto || !config) {
    const _destination = localStorage.getItem("_slugSearch");
    history.push(`/${_destination}`);
  }

  useEffect(() => {
    if (produto) {
      updateTotalOpcional(produto.complemento);
      setObservacao(produto.observacao);

      if (produto.meia) {
        let valorMeia = produto.valormeia > 0 ? produto.valormeia : preco / 2;

        setMeia(valorMeia);

        if (itemMeia) {
          setValue("MEIA");
        }
      }

      if (desativados.includes(produto.descricao)) {
        setSemEstoque(true);
      } else {
        setSemEstoque(false);
      }
    }
  }, [produto, itemMeia, desativados]);

  useEffect(() => {
    if (produto) {
      let precoFinal;
      if (value === "MEIA") {
        precoFinal = meia;
      } else {
        precoFinal = preco;
      }

      if (Object.keys(produto).length > 0) {
        setTotal(quantidade * (precoFinal + totalOpcional));
      }
    }
  }, [quantidade, totalOpcional, produto, meia, value]);

  function onComplementoChange(newcomp) {
    updateTotalOpcional(newcomp);
    setComplementos(newcomp);
  }

  function onComposicaoChange(newcomp) {
    setComposicoes(newcomp);
  }

  function updateTotalOpcional(complementos) {
    let nTotal = 0;
    complementos.map(
      (product) => (nTotal += product.quantidade * product.preco)
    );
    setTotalOpcional(nTotal);
  }

  function handleObsChange(e) {
    setObservacao(e.target.value);
  }

  function onValidade(value) {
    setValid(value);
  }

  function onConfirm() {
    let item = {
      produto: {
        ...produto,
        complemento: complementos,
        composicao: composicoes,
        observacao,
      },
      meia: value === "INTEIRA" ? false : true,
      quantidade: quantidade,
      itemcomposicao: composicoes.map(({ descricao, ativado }) => ({
        descricao,
        ativado,
      })),
      itemcomplemento: complementos
        .filter((item) => item.quantidade > 0)
        .map((origem) => ({
          descricao: origem.descricao,
          quantidade: origem.quantidade,
          preco: origem.preco,
          tipo: origem.tipo,
        })),
      observacao,
      valortotal: total,
    };

    if (type === "insert") {
      addItemCart(item);
    } else {
      updateItemCart(item, index);
    }

    history.goBack();
  }

  const SemEstoqueInfo = () => {
    return (
      <div
        style={{
          color: "orange",
          fontWeight: "bold",
          fontSize: "18px",
          textAlign: "center",
          marginTop: "10px",
        }}
      >
        Produto indisponível no momento!
      </div>
    );
  };

  const FooterActions = () => {
    return (
      <div className="p-grid">
        <div
          className="p-col-4 p-col-align-center"
          style={{ textAlign: "center" }}
        >
          <InputNumber
            inputStyle={{ width: "35px", textAlign: "center" }}
            id="horizontal"
            value={quantidade}
            onValueChange={(e) => {
              setQuantidade(e.value);
            }}
            showButtons
            buttonLayout="horizontal"
            step={1}
            incrementButtonIcon="pi pi-plus"
            decrementButtonIcon="pi pi-minus"
            incrementButtonClassName="backgroundColor:orange"
            min={1}
            mode="decimal"
            readOnly
          />
        </div>
        <div className="p-col-4" style={styles.valorTotal}>
          R$ {total.toFixed(2)}
        </div>
        <div className="p-col-4">
          <Button
            disabled={valid}
            onClick={onConfirm}
            style={{ width: "100%", justifyContent: "center" }}
          >
            {type === "insert" ? "ADICIONAR" : "ATUALIZAR"}
          </Button>
        </div>
      </div>
    );
  };

  if (!produto) return <div></div>;
  if (Object.keys(produto).length === 0) return <div></div>;

  return (
    <>
      <ToolBar back title={produto.descricao} />
      <div style={styles.main}>
        <InfoProduct
          config={config}
          item={produto}
          tipo={value}
          valorMeia={meia}
          opcional={totalOpcional}
        />
        {produto.meia && (
          <div>
            <div style={styles.labelPorcao}>Porção</div>
            <div id="combo-borda" style={styles.comboBorda}>
              <div style={styles.radioGroup}>
                <div
                  className="p-field-radiobutton"
                  style={{ marginTop: "10px", marginBottom: "30px" }}
                >
                  <RadioButton
                    inputId="city1"
                    name="city"
                    value="INTEIRA"
                    onChange={(e) => setValue(e.value)}
                    checked={value === "INTEIRA"}
                  />
                  <label htmlFor="city1">{`INTEIRA (R$ ${preco.toFixed(
                    2
                  )})`}</label>
                </div>
                <div className="p-field-radiobutton">
                  <RadioButton
                    inputId="city1"
                    name="MEIA"
                    value="MEIA"
                    onChange={(e) => setValue(e.value)}
                    checked={value === "MEIA"}
                  />
                  <label htmlFor="city1">{`MEIA (R$ ${meia.toFixed(
                    2
                  )})`}</label>
                </div>
              </div>
            </div>
          </div>
        )}
        {!cardapio && block && (
          <div className="" style={blockStyle}>
            {block}
          </div>
        )}
        <div className="p-grid p-dir-col" style={{ marginTop: "5px" }}>
          {produto.composicao.length > 0 && (
            <ComposicaoCard
              config={config}
              produto={produto}
              cardapio={cardapio || !isOpen}
              onComposicaoChange={onComposicaoChange}
            />
          )}
          {produto.complemento.length > 0 && (
            <ComplementoCard
              onValidate={onValidade}
              cardapio={cardapio || !isOpen}
              config={config}
              produto={produto}
              onComplementoChange={onComplementoChange}
            />
          )}
          {!cardapio && (
            <ObservacaoCard
              observacao={observacao}
              config={config}
              onChange={handleObsChange}
            />
          )}
        </div>
        {!cardapio && isOpen && !block && (
          <Footer style={{ padding: "10px" }}>
            {semEstoque ? <SemEstoqueInfo /> : <FooterActions />}
          </Footer>
        )}
      </div>
    </>
  );
};
