import React from "react";
import { CartItens } from "./CartItens";

export const CartList = ({ order, config }) => {
  const cartListStyle = {
    backgroundColor: "orange",
    color: config.theme.fontColor,
    padding: "10px",
    marginLeft: "0.5rem",
    marginRight: "0.5rem",
    marginTop: "5.6rem",
    borderRadius: "10px",
  };

  return (
    <div className="p-grid p-dir-col" style={cartListStyle}>
      {order?.itens?.map((item, index) => (
        <CartItens key={index} index={index} item={item} config={config} />
      ))}
    </div>
  );
};
