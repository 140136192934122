import React, { useContext, useEffect, useLayoutEffect, useRef } from "react";
import { ToolBar } from "../Home/ToolBar";
import { CartList } from "./CartList";
import { MainContext } from "../../Contexts/MainContext";
import { CartSummary } from "./CartSummary";
import { AddressCard } from "./AddressCard";
import { FooterCart } from "./CartFooter";
import { Payment } from "./Payment";
import { confirmaPedido } from "../../services/pedidoAPIService";
import { useHistory } from "react-router";
import { InputTextarea } from "primereact/inputtextarea";
import { useState } from "react";
import { Footer } from "../../Components/Footer";
import { Dropdown } from "primereact/dropdown";
import { QrReader } from "react-qr-reader";
import { v4 as uuid4 } from "uuid";
import "./styles.css";
import { Toast } from "primereact/toast";
import jwt from "jsonwebtoken";
import { Button } from "primereact/button";

export const CartView = (props) => {
  const toast = useRef(null);
  const history = useHistory();
  const {
    slug,
    entrega,
    mesa,
    ident,
    tipo,
    mesaSalva,
    setMesaSalva,
    addPixelPurchase,
  } = useContext(MainContext);
  const [observacao, setObservacao] = useState("");
  const [call, setCall] = useState(false);
  const [localIdent, setLocalIdent] = useState("");
  const [localHost, setLocalHost] = useState(null);
  const [viewQRCode, setViewQRCode] = useState(false);
  const [watch, setWatch] = useState(true);
  const [mesaRef, setMesaRef] = useState(mesaSalva);
  const [uuid, setUuid] = useState(null);
  const [msgCam, setMsgCam] = useState("Procurando");
  const [semEstoque, setSemEstoque] = useState(false);
  const [mesaList, setMesaList] = useState([]);
  const {
    config,
    order,
    getAddress,
    defineConfirmedOrder,
    payment,
    user,
    isOpen,
    localEntrega,
    cartItems,
    host,
    desativados,
  } = useContext(MainContext);

  const MesaList = [
    { label: "MESA 1", value: "1" },
    { label: "MESA 2", value: "2" },
    { label: "MESA 3", value: "3" },
    { label: "MESA 4", value: "4" },
    { label: "MESA 5", value: "5" },
    { label: "MESA 6", value: "6" },
    { label: "MESA 7", value: "7" },
    { label: "MESA 8", value: "8" },
    { label: "MESA 9", value: "9" },
    { label: "MESA 10", value: "10" },
    { label: "MESA 11", value: "11" },
    { label: "MESA 12", value: "12" },
    { label: "MESA 13", value: "13" },
    { label: "MESA 14", value: "14" },
    { label: "MESA 15", value: "15" },
    { label: "MESA 16", value: "16" },
    { label: "MESA 17", value: "17" },
    { label: "MESA 18", value: "18" },
    { label: "MESA 19", value: "19" },
    { label: "MESA 20", value: "20" },
    { label: "MESA 21", value: "21" },
    { label: "MESA 22", value: "22" },
    { label: "MESA 23", value: "23" },
    { label: "MESA 24", value: "24" },
    { label: "MESA 25", value: "25" },
    { label: "MESA 26", value: "26" },
    { label: "MESA 27", value: "27" },
    { label: "MESA 28", value: "28" },
    { label: "MESA 29", value: "29" },
    { label: "MESA 30", value: "30" },
    { label: "MESA 31", value: "31" },
    { label: "MESA 32", value: "32" },
    { label: "MESA 33", value: "33" },
    { label: "MESA 34", value: "34" },
    { label: "MESA 35", value: "35" },
    { label: "MESA 36", value: "36" },
    { label: "MESA 37", value: "37" },
    { label: "MESA 38", value: "38" },
    { label: "MESA 39", value: "39" },
    { label: "MESA 40", value: "40" },
    { label: "MESA 41", value: "41" },
    { label: "MESA 42", value: "42" },
    { label: "MESA 43", value: "43" },
    { label: "MESA 44", value: "44" },
    { label: "MESA 45", value: "45" },
    { label: "MESA 46", value: "46" },
    { label: "MESA 47", value: "47" },
  ];

  window.onbeforeunload = (event) => {
    const e = event || window.event;
    // Cancel the event
    e.preventDefault();
    if (e) {
      e.returnValue = ""; // Legacy method for cross browser support
    }
    return ""; // Legacy method for cross browser support
  };

  if (!order || !config) {
    console.log("redirecionando", props.match);
    const _destination = localStorage.getItem("_slugSearch");
    console.log(_destination);
    history.push(`/${_destination}`);
  }

  useEffect(() => {
    if (tipo === "ENTREGA") {
      if (!host && !localHost) {
        setCall(false);
      } else {
        setCall(true);
      }
    } else {
      setCall(false);
    }

    if (tipo === "COMANDA") {
      setCall(true);
    }

    if (tipo === "MESA") {
      setCall(true);
      setMesaRef(mesa);
    }

    if (config?.theme?.balcao) {
      setMesaList([{ label: "BALCÃO", value: "0" }, ...MesaList]);
    } else {
      setMesaList(MesaList);
    }
  }, [host, localHost, tipo, mesa]);

  useEffect(() => {
    if (cartItems && cartItems.length === 0) {
      history.push(`/${localStorage.getItem("_slugSearch")}`);
    }

    if (order) {
      if (Object.keys(order).length === 0) {
        history.push(`/${localStorage.getItem("_slugSearch")}`);
      }

      let _semEstoque = false;

      order.itens.forEach((item) => {
        if (desativados?.includes(item.produto.descricao)) {
          _semEstoque = true;
        }

        setSemEstoque(_semEstoque);
      });
    }
  }, [order, slug, history, desativados]);

  useEffect(() => {
    document.body.style.backgroundImage = "url('../static/1026.png')";
    document.body.style.backgroundBlendMode = "color-burn";
    document.body.style.backgroundRepeat = "repeat";
    document.body.style.height = "100vh";

    if (config && config.theme.qrcode) {
      setUuid(uuid4());
    }
  }, [config]);

  useLayoutEffect(() => {
    setViewQRCode(true);
  }, []);

  const obsStyle = {
    width: "100%",
    padding: "0px 8px 0 8px",
    marginTop: "8px",
  };

  const identStyle = {
    width: "100%",
    padding: "0px 8px 0 8px",
    marginTop: "8px",
  };

  const entregaObs = {
    width: "95%",
    marginTop: "8px",
    background: config?.theme?.secondary,
    border: "1px solid black",
    borderRadius: "10px",
    marginLeft: "8px",
    padding: "10px",
    textAlign: "center",
    animation: "entregamotion 2s infinite",
  };

  const mesaStyle = {
    background: config?.theme.secondary,
    border: "1px solid white",
    borderRadius: "10px",
    color: "#323232",
    fontWeight: "bold",
    width: "95%",
    marginTop: "5px",
    marginLeft: "8px",
    minHeight: "40px",
    padding: "10px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  };

  const editObs = {
    background: "#323232",
    border: "1px solid white",
    borderRadius: "10px",
    color: "white",
    width: "100%",
    minHeight: "50px",
  };

  async function onFind(value) {
    process.env.NODE_ENV === "development" &&
      console.log("scaneando...", watch);
    if (value && value.text.includes(slug) && value.text.includes(tipo)) {
      const intQuery = value.text.split("?")[1];
      const intParams = intQuery.split("&");
      const intMesa = intParams[0].split("=")[1];
      const intTipo = intParams[1].split("=")[1];
      setMsgCam(`Encontrado: Mesa: ${intMesa} tipo: ${intTipo}`);
      await onConfirmaPedido({ intMesa, intTipo });
      //      setWatch(false);
    } else {
      setMsgCam(`Procurando.`);
    }
  }

  const identInput = {
    background: "#323232",
    border: "1px solid white",
    borderRadius: "10px",
    color: "white",
    width: "100%",
    height: "40px",
    minHeight: "30px",
  };

  async function onConfirmaPedido(value) {
    const { intMesa, intTipo } = value;
    let _opt = "";
    setWatch(false);

    if (uuid) {
      let _obj = {
        key: uuid,
        mesa: intMesa ? +intMesa : +mesa,
        filial: +mesaRef,
      };
      _opt = jwt.sign(_obj, "button_menu");
    }

    console.log("criando pedido", tipo, localEntrega);

    let pedido = {
      cliente: user,
      itens: order.itens,
      webid: config.empresa.tenentID.toString(),
      entrega: tipo === "ENTREGA" ? localEntrega : getAddress(),
      formapagamento: payment ? payment.type : "Dinheiro",
      valortroco: payment ? payment.value : 0,
      valorentrega: entrega,
      status: "PENDENTE",
      ident: ident,
      mesa: intMesa ? +intMesa : +mesa,
      filial: +mesaRef,
      tipo: intTipo ? intTipo : tipo,
      host: uuid ? uuid : localHost ? localHost : host,
      observacao,
      opt: _opt,
    };

    setCall(false);
    let resp = await confirmaPedido(pedido);
    if (resp.status === 200) {
      defineConfirmedOrder(resp.data);

      if (!tipo) {
        console.log("registrando purchase");
        addPixelPurchase(resp.data.totalpedido);
      }

      setWatch(false);
      history.push(`/${slug}/step`);
    } else {
      setWatch(true);

      toast?.current?.show({
        severity: "Erro",
        summary: "Erro!",
        detail: resp?.statusText
          ? resp?.statusText
          : resp?.data?.error?.message,
      });
      setCall(true);
    }
  }

  if (order) {
    if (Object.keys(order).length === 0) return <></>;
  } else {
    return <></>;
  }

  const QrCodeType = () => {
    return (
      <div style={mesaStyle} id="divMesa">
        {tipo === "COMANDA" && (
          <Dropdown
            id={mesaRef ? "mesaCombo-ok" : "mesaCombo"}
            value={mesaRef}
            options={mesaList}
            onChange={(e) => {
              setMesaSalva(e.target.value);
              setMesaRef(e.target.value);
            }}
            scrollHeight={"330px"}
            placeholder="SELECIONE A SUA MESA"
            panelStyle={{
              color: "orange !important",
              animation: "paymotion 2s infinite !important",
            }}
            style={{
              animation: "paymotion 2s infinite !important",
              borderRadius: "7px",
              border: "1px solid #656565",
              backgroundColor: "#323232",
              color: "blue !important",
              width: "100%",
            }}
          />
        )}

        {mesaRef && (
          <>
            <div
              style={{ textAlign: "center" }}
            >{`Para finalizar o pedido escaneie o QR-code da ${tipo}`}</div>
            {viewQRCode && watch ? (
              <>
                <QrReader
                  scanDelay={250}
                  containerStyle={{
                    width: "60%",
                    marginLeft: "20%",
                    marginTop: "10px",
                  }}
                  videoContainerStyle={{
                    borderRadius: "10px",
                    width: "200px",
                    height: "200px",
                  }}
                  onResult={onFind}
                  videoStyle={{
                    border: "2px solid white",
                    backgroundColor: "black",
                  }}
                  constraints={{
                    facingMode: "environment",
                    focusMode: "continuous",
                    aspectRatio: "1",
                  }}
                />
                <div>{msgCam}</div>
                {process.env.NODE_ENV === "development" && (
                  <Button onClick={() => onConfirmaPedido(1, "COMANDA")}>
                    Fazer Pedido
                  </Button>
                )}
              </>
            ) : (
              <div></div>
            )}
          </>
        )}
      </div>
    );
  };

  const MesaType = () => {
    return (
      <div style={mesaStyle} id="divMesa">
        <div>{`${tipo}: ${localEntrega ? localEntrega : mesa}`}</div>
        {host ? <div>{`CLIENTE: ${host}`}</div> : <div></div>}
      </div>
    );
  };

  const ChooseType = () => {
    if (semEstoque) {
      return <div></div>;
    }

    return tipo ? config.theme.qrcode ? <QrCodeType /> : <MesaType /> : <></>;
  };

  const EntregaInterna = () => {
    return (
      !host &&
      tipo === "ENTREGA" && (
        <div style={identStyle}>
          <Dropdown
            id="payment"
            value={localHost}
            optionLabel="nome"
            optionValue="nome"
            options={config.clienteinterno}
            onChange={(e) => setLocalHost(e.target.value)}
            placeholder="Local de hospedagem"
            style={{
              borderRadius: "7px",
              border: "1px solid #656565",
              backgroundColor: "#323232",
              color: "white !important",
              width: "100%",
            }}
          />
        </div>
      )
    );
  };

  const PageFooter = ({ frete }) => {
    if (semEstoque) {
      return <div></div>;
    }

    if (config.theme.qrcode && tipo) {
      return <></>;
    }

    return isOpen ? (
      <FooterCart
        config={config}
        onConfirmaPedido={onConfirmaPedido}
        enabled={call}
        order={order}
        frete={frete}
      />
    ) : (
      <Footer>
        <div
          style={{
            color: "white",
            backgroundColor: "red",
            flex: "1",
            padding: "10px",
            textAlign: "center",
            height: "100%",
          }}
        >
          {config.empresa.atentimento}
        </div>
      </Footer>
    );
  };

  const IdentificacaoEntrega = () => {
    return (
      tipo === "ENTREGA" && (
        <div style={identStyle}>
          <InputTextarea
            style={identInput}
            className="p-col p-shadow-9"
            placeholder="Escreva uma Identificação"
            value={localIdent}
            onChange={(e) => setLocalIdent(e.target.value)}
          />
        </div>
      )
    );
  };

  const InfoEstoqueFim = () => {
    if (!semEstoque) {
      return <div></div>;
    }

    return (
      <div
        style={{
          backgroundColor: "Red",
          color: "white",
          borderRadius: "10px",
          marginTop: "8px",
          padding: "6px",
          margin: "0.5rem 0.5rem 0rem",
          textAlign: "center",
          fontWeight: "bold",
          border: "2px solid white",
        }}
      >
        Seu pedido contem produtos que não estão mais disponíveis. Remova os
        produtos em vermelho para continuar!
      </div>
    );
  };

  return (
    <>
      <Toast
        ref={toast}
        position="bottom-left"
        style={{
          backgroundColor: "rgba(255,0,0,0.9",
          color: "white",
          width: "100%",
        }}
      />
      <ToolBar back title="Carrinho" />
      <CartList order={order} config={config} />
      <InfoEstoqueFim />
      {config?.theme?.msgentrega && user && !tipo && (
        <div style={entregaObs}>{config.theme.msgentrega}</div>
      )}
      {config?.theme?.pausaentrega && user && !tipo && (
        <div style={entregaObs}>Apenas retirada no local</div>
      )}
      {user && !tipo && <AddressCard config={config} />}
      <div style={obsStyle}>
        <InputTextarea
          style={editObs}
          className="p-col p-shadow-9"
          placeholder="Alguma Observação?"
          autoResize
          value={observacao}
          onChange={(e) => setObservacao(e.target.value)}
        />
      </div>
      <ChooseType />
      <EntregaInterna />
      <IdentificacaoEntrega />
      <CartSummary order={order} config={config} frete={entrega} />
      {!tipo && !semEstoque && <Payment config={config} />}
      <PageFooter frete={entrega} />
    </>
  );
};
