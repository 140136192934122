import React, { useContext } from "react";
import { Button } from "primereact/button";
import { MainContext } from "../../Contexts/MainContext";
import { PrimeIcons } from "primereact/api";
import { Toolbar } from "primereact/toolbar";
import { useHistory } from "react-router-dom";
import { IoFastFoodOutline, IoPersonOutline } from "react-icons/io5";

export const ToolBar = (props) => {
  const { back, title, userShow, cartShow, orderShow } = props;
  const {
    config,
    user,
    cartItems,
    slug,
    getTotal,
    confirmedOrder,
    cardapio,
    tipo,
  } = useContext(MainContext);
  const history = useHistory();

  const styles = {
    title: {
      fontSize: "14px",
      color: "white",
      fontWeight: "bold",
    },

    toolBar: {
      backgroundColor: config?.theme?.toolbar,
      border: "0px",
      filter: "drop-shadow(2px 4px 6px black)",
      minHeight: "64px",
      position: "fixed",
      width: "100%",
      zIndex: "333",
      top: "0",
    },
    button: {
      fontWeight: "bold",
      borderRadius: "20px",
      animation: "cartAnimation 2s infinite",
    },
  };

  function onRegister() {
    history.push(`/${slug}/register`);
  }

  function onGoBack(e) {
    e.preventDefault();
    history.goBack();
  }

  function goStep() {
    history.push(`/${slug}/step`);
  }

  const leftButtons = (
    <>
      {back && (
        <Button
          className="p-button-rounded p-button-text"
          icon={PrimeIcons.CHEVRON_LEFT}
          onClick={onGoBack}
        />
      )}
      {!cardapio && !tipo && userShow && user && (
        <Button className="p-button-rounded p-shadow-9" onClick={onRegister}>
          <IoPersonOutline />
        </Button>
      )}
      {!cardapio && !tipo && orderShow && confirmedOrder && (
        <Button className="p-button-rounded p-shadow-9 p-ml-2" onClick={goStep}>
          <IoFastFoodOutline />
        </Button>
      )}
      {title && (
        <div className="p-text-center" style={styles.title}>
          {title}
        </div>
      )}
    </>
  );

  const rightButtons = (
    <>
      {cartShow && cartItems && cartItems.length > 0 && (
        <Button
          style={styles.button}
          className="p-shadow-9 button-cart"
          label={`R$ ${getTotal().toFixed(2)}`}
          icon={PrimeIcons.SHOPPING_CART}
          onClick={() => history.push(`/${slug}/cart`)}
        />
      )}
    </>
  );

  return (
    <Toolbar left={leftButtons} right={rightButtons} style={styles.toolBar} />
  );
};
