import React, { useState, useEffect, useContext } from "react";
import { AddressCartItem } from "./AddressCardItem";
import { MainContext } from "../../Contexts/MainContext";
import { useHistory } from "react-router-dom";

export const AddressSelect = ({ user }) => {
  const [select, setSelect] = useState(-1);
  const { setAddress, config, opt, slug, pausaEntrega } =
    useContext(MainContext);
  const [bairroList, setBairroList] = useState([]);
  const history = useHistory();

  useEffect(() => {
    let _temp = [];

    config.bairros.forEach((el) => _temp.push(el.descricao.toLowerCase()));

    if (opt.cidades) {
      opt.cidades.forEach((el) => _temp.push(el.cidade.toLowerCase()));
    }

    if (_temp.length === 0) {
      setBairroList(user?.enderecos);
      return;
    }

    if (user) {
      setBairroList(
        user?.enderecos?.filter((ed) => _temp.includes(ed.bairro.toLowerCase()))
      );
    }

    if (opt.cidades) {
      setBairroList(
        user?.enderecos?.filter((ed) => _temp.includes(ed.cidade.toLowerCase()))
      );
    }

    if (config?.empresa?.retirar === "0" && bairroList.length === 1) {
      setSelect(0);
    }
  }, [config, user, opt, bairroList?.length]);

  useEffect(() => {
    setAddress(select);
  }, [select, setAddress]);

  function getPrice(bairro, cidade) {
    let findBairro = config.bairros.find(
      (element) => element.descricao.toLowerCase() === bairro.toLowerCase()
    );

    if (opt.cidades) {
      let findCidade = opt.cidades.find(
        (cid) => cid.cidade.toLowerCase() === cidade.toLowerCase()
      );

      if (findCidade) {
        return findCidade.valor;
      }
    }

    if (opt.entrega) {
      return opt.entrega;
    }

    if (findBairro) {
      return findBairro.valorentrega;
    } else {
      return opt.entrega ? opt.entrega : 0;
    }
  }

  return (
    <div className="p-grid p-dir-col ">
      {!pausaEntrega &&
        bairroList?.map((end, index) => (
          <AddressCartItem
            key={index}
            adress={end}
            value={index}
            price={getPrice(end.bairro, end.cidade)}
            //onChange={(e) => setSelect(e.value)}
            checked={select === index}
            onSet={setSelect}
          />
        ))}
      {config?.empresa?.retirar === "1" && (
        <AddressCartItem
          input="retirar"
          label="Retirar no Local"
          value={999}
          price={0}
          checked={select === 999}
          //onChange={(e) => setSelect(e.value)}
          onSet={setSelect}
        />
      )}
      {!pausaEntrega && (
        <div
          className="p-grid p-justify-between p-shadow-8"
          onClick={() => history.push(`/${slug}/register`)}
          style={{
            border: "1px solid black",
            marginTop: "1rem",
            marginLeft: "0.2rem",
            marginRight: "0.2rem",
            minHeight: "3rem",
            borderRadius: "10px",
            justifyContent: "center",
            textAlign: "center",
            alignContent: "center",
            padding: "5px",
            backgroundColor: "black",
            color: "white",
          }}
        >
          ADICIONAR NOVO ENDEREÇO
        </div>
      )}
    </div>
  );
};
