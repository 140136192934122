import React from "react";
import { RadioButton } from "primereact/radiobutton";

export const AddressCartItem = ({
  value,
  adress,
  price,
  input,
  onSet,
  ...props
}) => {
  const labelStyle = {
    padding: 0,
    paddingTop: "0.4rem",
    paddingBotton: "0.3rem",
    marginTop: "0.2rem",
    maxHeight: "30px",
  };

  return (
    <div
      className="p-grid p-justify-between p-shadow-8"
      onClick={() => onSet(value)}
      style={{
        border: "1px solid black",
        marginTop: "1rem",
        marginLeft: "0.2rem",
        marginRight: "0.2rem",
        minHeight: "4rem",
        borderRadius: "10px",
      }}
    >
      <RadioButton
        className="p-col-1"
        style={{ marginLeft: "15px", ...labelStyle }}
        inputId={input}
        value={value}
        {...props}
      />
      <label className="p-col-8" style={labelStyle}>
        {adress
          ? `${adress?.endereco}, ${adress?.numero} ${adress?.bairro}`
          : "Retirar no Local"}
      </label>
      <label className="p-col-2" style={{ marginRight: "5px", ...labelStyle }}>
        {price === 0 ? "Grátis" : `R$ ${price.toFixed(2)}`}
      </label>
    </div>
  );
};
