import moment from "moment";
import "moment/locale/pt-br";

export function normalTime(dados, theme, mesa, tipo, setIsOpen) {
  moment.locale("pt-br");
  const current = moment();

  let _abertura = moment(dados.abertura).locale("pt-br");
  let _fechamento = moment(dados.fechamento).locale("pt-br");
  let _diaSemana = current.format("ddd");
  let _abertura2 = null;
  let _fechamento2 = null;
  let abertura2 = null;
  let fechamento2 = null;
  let turno1 = false;

  if (theme.turno2) {
    _abertura2 = moment(theme.turno2.abertura).locale("pt-br");
    _fechamento2 = moment(theme.turno2.fechamento).locale("pt-br");

    abertura2 = moment({
      hour: _abertura2.hour(),
      minute: _abertura2.minute(),
    });
    fechamento2 = moment({
      hour: _fechamento2.hour(),
      minute: _fechamento2.minute(),
    });
  }

  let abertura = moment({
    hour: _abertura.hour(),
    minute: _abertura.minute(),
  });
  let fechamento = moment({
    hour: _fechamento.hour(),
    minute: _fechamento.minute(),
  });

  if (abertura.isSameOrAfter(fechamento)) {
    if (current.hour() >= 0 && current.hour() <= fechamento.hour()) {
      abertura.add("-1", "day");
    } else {
      fechamento.add("1", "day");
    }
  }

  if (!mesa && !tipo) {
    if (
      current.isSameOrAfter(abertura) &&
      current.isSameOrBefore(fechamento) &&
      theme.horario[_diaSemana]
    ) {
      turno1 = true;
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }

    if (abertura2 && !turno1) {
      if (
        current.isSameOrAfter(abertura2) &&
        current.isSameOrBefore(fechamento2) &&
        theme.horario[_diaSemana]
      ) {
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }
    }
  }
}

export function advancedTime(dados, theme, mesa, tipo, setIsOpen) {
  moment.locale("pt-br");
  const current = moment();
  const list = [];
  const json = JSON.parse(dados.json);
  const semana = current.format("ddd");

  if (mesa || tipo) {
    setIsOpen(true);
    return;
  }

  console.log("horário avançado");

  const _avancado = json.avancado;

  list.push(
    ...AddDay("seg", _avancado.segunda, _avancado.sega1, _avancado.segf1)
  );
  list.push(
    ...AddDay("seg", _avancado.segunda, _avancado.sega2, _avancado.segf2)
  );
  list.push(
    ...AddDay("ter", _avancado.terca, _avancado.tera1, _avancado.terf1)
  );
  list.push(
    ...AddDay("ter", _avancado.terca, _avancado.tera2, _avancado.terf2)
  );
  list.push(
    ...AddDay("qua", _avancado.quarta, _avancado.quaa1, _avancado.quaf1)
  );
  list.push(
    ...AddDay("qua", _avancado.quarta, _avancado.quaa2, _avancado.quaf2)
  );
  list.push(
    ...AddDay("qui", _avancado.quinta, _avancado.quia1, _avancado.quif1)
  );
  list.push(
    ...AddDay("qui", _avancado.quinta, _avancado.quia2, _avancado.quif2)
  );
  list.push(
    ...AddDay("sex", _avancado.sexta, _avancado.sexa1, _avancado.sexf1)
  );
  list.push(
    ...AddDay("sex", _avancado.sexta, _avancado.sexa2, _avancado.sexf2)
  );
  list.push(
    ...AddDay("sáb", _avancado.sabado, _avancado.saba1, _avancado.sabf1)
  );
  list.push(
    ...AddDay("sáb", _avancado.sabado, _avancado.saba2, _avancado.sabf2)
  );
  list.push(
    ...AddDay("dom", _avancado.domingo, _avancado.doma1, _avancado.domf1)
  );
  list.push(
    ...AddDay("dom", _avancado.domingo, _avancado.doma2, _avancado.domf2)
  );

  let aberto = false;

  list.forEach((item) => {
    if (item[semana]) {
      let _abertura = moment({
        hour: item.abertura.hour(),
        minute: item.abertura.minute(),
      }).locale("ptBR");
      let _fechamento = moment({
        hour: item.fechamento.hour(),
        minute: item.fechamento.minute(),
      });

      if (
        current.isSameOrAfter(_abertura) &&
        current.isSameOrBefore(_fechamento)
      ) {
        aberto = true;
      }
    }
  });

  console.log("aberto => ", aberto);

  setIsOpen(aberto);
}

function AddDay(dia, aberto, inicio, fim) {
  const _list = [];

  if (moment(inicio).isSame(moment(fim))) return [];

  if (moment(fim).isBefore(moment(inicio))) {
    _list.push({
      [dia]: aberto,
      abertura: moment(inicio),
      fechamento: moment("1899-12-30T23:59:59"),
    });

    _list.push({
      [nextDay(dia)]: aberto,
      abertura: moment("1899-12-30T00:00:00"),
      fechamento: moment(fim),
    });
  } else {
    _list.push({
      [dia]: aberto,
      abertura: moment(inicio.replace(".000Z", "")),
      fechamento: moment(fim.replace(".000Z", "")),
    });
  }

  return _list;
}

function nextDay(dia) {
  if (dia === "seg") {
    return "ter";
  }
  if (dia === "ter") {
    return "qua";
  }
  if (dia === "qua") {
    return "qui";
  }
  if (dia === "qui") {
    return "sex";
  }
  if (dia === "sex") {
    return "sáb";
  }
  if (dia === "sáb") {
    return "dom";
  }
  if (dia === "dom") {
    return "seg";
  }
}
