import React from "react";
import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
import classNames from "classnames/bind";

import "./Input.css";

export const Input = ({ id, label, innerRef, error, ...props }) => {
  const inptClass = classNames({
    "c-input": true,
    "p-inputtext": true,
    "p-invalid": error,
  });

  return (
    <span style={{ ...props.style }}>
      <label style={{ color: "#323232", alignSelf: "center" }} htmlFor={id}>
        {label}
      </label>
      {props.type !== "mask" ? (
        <InputText
          {...props}
          autoComplete="off"
          className={inptClass}
          name={props.name}
          id={id}
          ref={innerRef}
          style={{
            borderRadius: "10px",
            backgroundColor: "cornsilk",
            color: "black",
            width: "100%",
            filter: "drop-shadow(1px 1px 4px rgba(0,0,0,0.5))",
            border: 0,
            marginTop: "7px",
            marginBottom: "5px",
          }}
        />
      ) : (
        <InputMask
          name={props.name}
          id={id}
          ref={innerRef}
          style={{
            borderRadius: "10px",
            backgroundColor: "cornsilk",
            color: "black",
            width: "100%",
          }}
          onChange={props.onChange}
          value={props.value && props.value.value}
          mask="(99)99999-9999"
          showIcon
          inputStyle={{
            borderBottomLeftRadius: "10px",
            borderTopLeftRadius: "10px",
            backgroundColor: "cornsilk",
            filter: "drop-shadow(1px 1px 4px rgba(0,0,0,0.5))",
          }}
        ></InputMask>
      )}
    </span>
  );
};
