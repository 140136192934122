import React, { useEffect, useState, useContext } from "react";
import ProductCard from "./ProductCard";
import { MainContext } from "../../Contexts/MainContext";
import { IoChevronDownOutline } from "react-icons/io5";
import { Accordion, AccordionDetails, AccordionSummary } from "./Styled";
import { Collapse } from "@material-ui/core";
import moment from "moment";
import "moment/locale/pt-br";

export const ProductCardList = (props) => {
  const {
    filterList,
    setFilterList,
    categoryList,
    fullList,
    openCategori,
    setOpenCategori,
    desativados,
    mesa,
    cardapio,
  } = useContext(MainContext);
  const [index, setIndex] = useState(openCategori.index);
  const [expanded, setExpanded] = useState(openCategori.panel);
  const [listaCategoria, setListaCategoria] = useState([]);
  const { config } = props;
  moment.locale("pt-br");
  const current = moment();
  const diasemana = current.format("ddd").replace("sáb", "sab");

  const styles = {
    accordionStyle: {
      padding: "0rem",
      backgroundColor: "transparent",
      alignItems: "center",
    },

    categoriaStyle: {
      backgroundColor: "rgba(0,0,0,0.4)",
      color: "orange",
      border: "1px solid orange",
      width: "94%",
      fontWeight: "bold",
      marginTop: "0px",
      marginBottom: "19px",
      padding: "10px",
      textAlign: "center",
      borderRadius: "10px",
    },
    acordionDetail: {
      flex: "1",
      flexDirection: "column",
      backgroundColor: "transparent",
      alignItems: "center",
    },
    acordionSummary: {
      backgroundColor: "orange",
      fontWeight: "bold",
      border: "0px",
    },
  };

  useEffect(() => {
    const _newCategory = new Set();

    const _list = fullList
      .filter((item) => {
        if (item.json === "") return true;

        const j = JSON.parse(item.json);

        if (!j.tempo) return true;

        return j.tempo[diasemana] === true;
      })
      .filter((obj) => (mesa ? obj.loja : cardapio ? obj.loja : obj.web))
      .filter((item) => {
        if (item.json === "") return true;

        const j = JSON.parse(item.json);

        if (!j.tempo) return true;

        let _abertura = moment(j.tempo[`${diasemana}horaini`]).locale("pt-br");
        let _fechamento = moment(j.tempo[`${diasemana}horafim`]).locale(
          "pt-br"
        );

        let abertura = moment({
          hour: _abertura.hour(),
          minute: _abertura.minute(),
        });
        let fechamento = moment({
          hour: _fechamento.hour(),
          minute: _fechamento.minute(),
        });

        if (abertura.isSameOrAfter(fechamento)) {
          if (current.hour() >= 0 && current.hour() <= fechamento.hour()) {
            abertura.add("-1", "day");
          } else {
            fechamento.add("1", "day");
          }
        }

        if (j.tempo["naomostra"]) {
          if (
            current.isSameOrAfter(abertura) &&
            current.isSameOrBefore(fechamento)
          ) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      });

    _list.forEach((element) => {
      _newCategory.add(element.categoria);
    });

    const _filterCategory = categoryList.filter((item) =>
      _newCategory.has(item)
    );

    let newList = fullList.filter(
      (item) => item.categoria === _filterCategory[index]
    );

    setListaCategoria(_filterCategory);
    setFilterList(newList);
  }, [index, setFilterList, fullList, categoryList]);

  const handlePanelChange = (index, panel) => (event, newExpanded) => {
    setIndex(index);
    setExpanded(newExpanded ? panel : false);
    setOpenCategori({ index, panel });
  };

  return (
    <>
      {listaCategoria.map((categoria, i) => (
        <Accordion
          key={i}
          style={styles.accordionStyle}
          onChange={handlePanelChange(i, categoria)}
          TransitionProps={{
            unmountOnExit: true,
            exit: false,
          }}
          expanded={expanded === categoria}
          TransitionComponent={Collapse}
        >
          <AccordionSummary
            expandIcon={<IoChevronDownOutline />}
            key={categoria}
            name="a1"
            style={styles.acordionSummary}
          >
            <div>{categoria}</div>
          </AccordionSummary>
          <AccordionDetails style={styles.acordionDetail}>
            {config.categorias[categoria] &&
            config.categorias[categoria] !== "" ? (
              <div className="teste" style={styles.categoriaStyle}>
                {config.categorias[categoria]}
              </div>
            ) : null}

            {filterList
              .sort((a, b) => (a.ordemdisplay > b.ordemdisplay ? 1 : -1))
              .filter((obj) => !desativados.includes(obj.descricao))
              .filter((obj) =>
                mesa ? obj.loja : cardapio ? obj.loja : obj.web
              )
              .map((item) => (
                <ProductCard
                  index={item.id}
                  key={item.id}
                  item={item}
                  config={config}
                />
              ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};
