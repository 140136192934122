import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { MainContext } from "../../Contexts/MainContext";
import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames/bind";
import { Dialog } from "primereact/dialog";
import { registerForm } from "../../services/clienteAPIService";
import { ProgressSpinner } from "primereact/progressspinner";
import "./style.css";

const Index = (props) => {
  const [showMessage, setShowMessage] = useState(false);
  const { setSlug, config } = useContext(MainContext);
  const [formError, setFormError] = useState("");
  const [isSending, setIsSending] = useState(false);
  const { slug } = props.match.params;
  const [check, setCheck] = useState(false);
  const sexo = [
    { label: "MASCULINO", value: "MASCULINO" },
    { label: "FEMININO", value: "FEMININO" },
  ];
  const defaultValues = {
    name: "",
    cpf: "",
    contato: "",
    date: null,
    sexo: "",
    cidade: "",
  };

  useEffect(() => {
    document.body.style.backgroundColor = "#323232 !important";
    setSlug(slug);
  }, [slug, setSlug]);

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({ defaultValues });

  const onSubmit = async (data) => {
    setIsSending(true);
    let _resp = await registerForm({ ...data, id: config.empresa.tenentID });

    if (_resp.status === 401) {
      setFormError("Cliente já Cadastrado!");
      setShowMessage(true);
      setCheck(false);
      reset();
    } else if (_resp.status === 201) {
      setFormError("");
      setShowMessage(true);
      setCheck(false);
      reset();
    } else {
      setFormError(_resp.statusText);
      setShowMessage(true);
      setCheck(false);
      reset();
    }

    setIsSending(false);
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  const dialogFooter = (
    <div className="p-d-flex p-jc-center">
      <Button
        label="OK"
        autoFocus
        className="p-button-text"
        onClick={() => setShowMessage(false)}
        style={{ backgroundColor: "black", width: "60%", borderRadius: "20px" }}
      />
    </div>
  );

  return (
    <div
      style={{
        backgroundColor: "black",
        flex: 1,
        justifyContent: "center",
        display: "flex",
        minHeight: "100vh",
        alignItems: "center",
      }}
    >
      <div
        style={{
          color: "white",
          backgroundColor: "white",
          justifyContent: "flex-start",
          borderRadius: "10px",
          flexDirection: "column",
          alignItems: "center",
          display: "flex",
          height: "90%",
          maxWidth: "400px",
        }}
      >
        <Dialog
          visible={showMessage}
          onHide={() => setShowMessage(false)}
          position="top"
          modal
          footer={dialogFooter}
          showHeader={false}
          breakpoints={{ "960px": "80vw" }}
          style={{
            minWidth: "80vw",
            width: "30vw",
            backgroundColor: "white",
            borderRadius: "30px",
          }}
        >
          <div className="p-d-flex p-ai-center p-dir-col p-pt-6 p-px-3">
            <i
              className={
                formError !== "" ? "pi pi-times-circle" : "pi pi-check-circle"
              }
              style={{
                fontSize: "5rem",
                color: formError !== "" ? "red" : "lime",
              }}
            ></i>
            <h5>
              {formError !== ""
                ? "Registro não concluido!"
                : "Registro Concluído!"}
            </h5>
            <p
              style={{
                lineHeight: 1.5,
                textIndent: "1rem",
                textAlign: "center",
              }}
            >
              {formError !== ""
                ? formError
                : "Seu cadastro foi inserido com sucesso em nossa base de dados!"}
            </p>
          </div>
        </Dialog>
        <img
          style={{
            width: "70%",
            height: "8rem",
            marginTop: "1rem",
            marginBottom: "2rem",
          }}
          alt="Card"
          src={`../static/${slug}/logo.jpg`}
        />
        <label
          style={{
            color: "black",
            fontWeight: "bold",
            fontSize: "24px",
            marginBottom: "20px",
          }}
        >
          Formulário de Registro
        </label>
        <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
          <div
            style={{
              maxWidth: "400px",
              padding: "20px",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              className="p-field"
              style={{ marginBottom: "30px", width: "100%" }}
            >
              <span className="p-float-label">
                <Controller
                  name="name"
                  control={control}
                  rules={{ required: "Nome é obrigatório." }}
                  render={(field, fieldState) => {
                    return (
                      <InputText
                        id={field.name}
                        {...field}
                        autoFocus
                        style={{
                          border: "1px solid ",
                          borderColor: fieldState.invalid ? "red" : "#ced4da",
                        }}
                        className={classNames({
                          "p-invalid": fieldState.invalid,
                        })}
                      />
                    );
                  }}
                />

                <label htmlFor="username">Nome</label>
              </span>
              {getFormErrorMessage("name")}
            </div>
            <div
              className="p-field"
              style={{ marginBottom: "30px", width: "100%" }}
            >
              <span className="p-float-label">
                <Controller
                  name="cpf"
                  control={control}
                  rules={{ required: "CPF é obrigatório" }}
                  render={(field, fieldState) => {
                    return (
                      <InputMask
                        id={field.cpf}
                        {...field}
                        mask="999.999.999-99"
                        value={field.value}
                        onChange={(e) => field.onChange(e.value)}
                        style={{
                          border: "1px solid ",
                          borderColor: fieldState.invalid ? "red" : "#ced4da",
                        }}
                      />
                    );
                  }}
                />
                <label htmlFor="username">CPF</label>
              </span>
              {getFormErrorMessage("cpf")}
            </div>
            <div
              className="p-field"
              style={{ marginBottom: "30px", width: "100%" }}
            >
              <span className="p-float-label">
                <Controller
                  name="contato"
                  control={control}
                  rules={{ required: "Contato é obrigatório" }}
                  render={(field, fieldState) => {
                    return (
                      <InputMask
                        id={field.contato}
                        {...field}
                        mask="(99) 99999-9999"
                        value={field.value}
                        onChange={(e) => field.onChange(e.value)}
                        style={{
                          border: "1px solid ",
                          borderColor: fieldState.invalid ? "red" : "#ced4da",
                        }}
                      ></InputMask>
                    );
                  }}
                />
                <label htmlFor="username">Contato</label>
              </span>
              {getFormErrorMessage("contato")}
            </div>

            <div
              className="p-field"
              style={{ marginBottom: "30px", width: "100%" }}
            >
              <span className="p-float-label">
                <Controller
                  name="date"
                  control={control}
                  render={(field, fieldState) => {
                    return (
                      <InputMask
                        id={field.date}
                        {...field}
                        mask="99/99/9999"
                        value={field.value}
                        onChange={(e) => field.onChange(e.value)}
                        style={{
                          border: "1px solid ",
                          borderColor: fieldState.invalid ? "red" : "#ced4da",
                        }}
                      ></InputMask>
                    );
                  }}
                />
                <label htmlFor="username">Data Nascimento</label>
              </span>
              {getFormErrorMessage("date")}
            </div>
            <div
              className="p-field"
              style={{ marginBottom: "30px", width: "100%" }}
            >
              <span className="p-float-label">
                <Controller
                  name="cidade"
                  control={control}
                  render={(field, fieldState) => {
                    return (
                      <InputText
                        id={field.cidade}
                        {...field}
                        autoFocus
                        style={{
                          border: "1px solid ",
                          borderColor: fieldState.invalid ? "red" : "#ced4da",
                        }}
                        className={classNames({
                          "p-invalid": fieldState.invalid,
                        })}
                      />
                    );
                  }}
                />

                <label htmlFor="username">Cidade</label>
              </span>
              {getFormErrorMessage("cidade")}
            </div>
            <div
              className="p-field"
              style={{ marginBottom: "30px", width: "100%" }}
            >
              <span className="p-float-label">
                <Controller
                  name="sexo"
                  control={control}
                  render={(field, fieldState) => {
                    return (
                      <Dropdown
                        style={{ animation: "none !important" }}
                        optionLabel="label"
                        value={field.value}
                        options={sexo}
                        onChange={(e) => field.onChange(e.value)}
                      />
                    );
                  }}
                />

                <label htmlFor="username">Sexo</label>
              </span>
            </div>
            <div
              className="p-col-12"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Checkbox
                inputId="cb1"
                checked={check}
                onChange={(e) => setCheck(e.checked)}
                style={{
                  color: "black",
                }}
              ></Checkbox>
              <label
                htmlFor="cb1"
                className="p-checkbox-label"
                style={{
                  color: "black",
                  fontSize: 12,
                  fontWeight: "bold",
                  justifyContent: "center",
                  marginLeft: 10,
                }}
              >
                {`Permito a ${config?.empresa?.nome} utilizar meus dados apenas para promoções e
                controle interno.`}
              </label>
            </div>
            <Button
              disabled={!check || isSending}
              type="submit"
              style={{
                width: "100%",
                backgroundColor: "black",
                color: "white",
                textAlign: "center",
                borderRadius: "10px",
                height: "60px",
                justifyContent: "center",
                border: "1px solid white",
                marginTop: 10,
              }}
            >
              {isSending ? (
                <ProgressSpinner style={{ width: "60%", height: "40px" }} />
              ) : (
                "Confirmar Registro"
              )}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Index;
