import axios from "axios";

async function registerForm(payload) {
  let resp;

  let _usuario = {
    nome: payload.name,
    contato: payload.contato,
    cpf: payload.cpf,
    datanasc: payload.date,
    processado: false,
    webid: payload.id,
    sexo: payload.sexo,
    cidade: payload.cidade,
  };

  try {
    resp = await axios.post("/clienteformapi", _usuario);

    return resp;
  } catch (e) {
    console.log(e);
    const { response } = e;

    if (response?.data?.error?.message.includes("Duplicate")) {
      return { statusText: "Cliente já cadastrado!", status: "401", msg: e };
    } else {
      return { statusText: "Erro de conexão", status: "400", msg: e };
    }
  }
}

async function login(telefone, idade) {
  let usuario = { telefone: telefone, idade: idade };
  let resp;

  try {
    resp = await axios.post("/clienteserviceapi/getuser", usuario);

    return resp;
  } catch (e) {
    console.log(e);
    const { response } = e;

    if (response) {
      return response;
    } else {
      return { statusText: "Erro de conexão", status: "400" };
    }
  }
}

async function refreshUser(payload) {
  try {
    const resp = await axios.post("/loginapiservice/login", payload);
    return resp;
  } catch (e) {
    console.log(e);
    const { response } = e;

    if (response) {
      return response;
    } else {
      return { statusText: "Erro de conexão", status: "400" };
    }
  }
}

async function registerUser(payload) {
  let resp;

  try {
    if (payload.cliente.id) {
      resp = await axios.post("/loginapiservice/atualiza", payload);
    } else {
      resp = await axios.post("/loginapiservice/login", payload);
    }

    return resp;
  } catch (e) {
    console.log(e);
    const { response } = e;

    if (response) {
      return response;
    } else {
      return { statusText: "Erro de conexão", status: "400" };
    }
  }
}

export { login, registerUser, registerForm, refreshUser };
