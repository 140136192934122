import React, { useContext, useEffect, useState, useRef } from "react";
//import { Steps } from "primereact/steps";
import "./index.css";
import { ToolBar } from "../Home/ToolBar";
import { MainContext } from "../../Contexts/MainContext";
import { Separator } from "../../Components/Separator";
import { useInterval } from "../../Components/utils";
import { verificaStatus } from "../../services/pedidoAPIService";
import { Button } from "primereact/button";
import { useHistory } from "react-router";
import * as Status from "../../Constants/Status";
import Steps from "rc-steps";
import "rc-steps/assets/index.css";
import { IoCheckmarkSharp, IoAlertOutline } from "react-icons/io5";
import moment from "moment";
import { Toast } from "primereact/toast";

export const StepView = (props) => {
  const {
    confirmedOrder,
    clearOrder,
    updateConfirmedOrder,
    updateTime,
    mesa,
    tipo,
    localEntrega,
    slugWithSearch,
    config,
    opt,
  } = useContext(MainContext);
  const history = useHistory();
  const [index, setIndex] = useState(0);
  const [d, setD] = useState(Date());
  const toast = useRef(null);

  if (!confirmedOrder) {
    history.push(`/${props.match.params.slug}`);
  }

  useEffect(() => {
    if (confirmedOrder) {
      switch (confirmedOrder.status) {
        case Status.PENDENTE:
          setIndex(0);
          break;
        case Status.ACEITO:
          setIndex(1);
          break;
        case Status.ENTREGA:
          setIndex(2);
          break;
        case Status.CONCLUIDO:
          setIndex(3);
          break;
        default:
          console.log(`Não encontrado ${confirmedOrder.status}`);
      }

      setD(new Date(confirmedOrder.datarecebido));
    }
  }, [confirmedOrder, updateTime]);

  useInterval(() => {
    async function load() {
      let resp = await verificaStatus(confirmedOrder.id);

      if (resp.status === 200 && resp.data.status !== confirmedOrder.status) {
        updateConfirmedOrder(resp.data);
      }
    }

    if (confirmedOrder.tipo === "") {
      load();
    }
  }, 5000);

  if (confirmedOrder) {
    if (Object.keys(confirmedOrder).length === 0) return <div></div>;
  } else return <div></div>;

  function onGoBack() {
    clearOrder();
    history.push(`/${slugWithSearch}`);
  }

  return (
    <>
      <Toast ref={toast} position="bottom-left"></Toast>
      <ToolBar title="Status do Pedido" />
      <div
        className="p-shadow-9"
        style={{
          border: "2px solid white",
          padding: "0.3rem",
          margin: "1rem",
          marginTop: "4.3rem",
          backgroundColor: "orange",
          color: "white",
          borderRadius: "10px",
          fontWeight: "bold",
        }}
      >
        {!config?.theme.qrcode && (
          <div style={{ margin: "0.5rem", color: "#323232" }}>
            {mesa ? `${tipo}: ${mesa}` : `PEDIDO Nº: ${confirmedOrder.numero}`}
          </div>
        )}
        <div style={{ margin: "0.5rem", color: "#323232" }}>
          DATA/HORA: {d && d.toLocaleString("pt-BR")}
        </div>
      </div>
      <div
        style={{
          border: "2px solid white",
          padding: "1rem",
          margin: "1rem",
          backgroundColor: "orange",
          color: "white",
          borderRadius: "10px",
          display: !mesa ? "block" : "none",
        }}
        className="p-shadow-9"
      >
        {tipo && (
          <div style={{ color: "#323232", fontWeight: "bold" }}>
            <div>CLIENTE: {confirmedOrder.host}</div>
            <div>ENTREGA: {localEntrega}</div>
          </div>
        )}
        {!tipo && (
          <div style={{ padding: "1rem 0 0" }}>
            <Steps
              direction="vertical"
              current={index}
              icons={{
                finish: <IoCheckmarkSharp />,
                error: <IoAlertOutline />,
              }}
              status={
                confirmedOrder.mensagemCancelamento !== "" ? "error" : "finish"
              }
            >
              <Steps.Step
                title="Pedido Efetuado"
                description={moment(confirmedOrder?.updateTime.PENDENTE).format(
                  "DD/MM/YY - HH:mm:ss"
                )}
              />
              <Steps.Step
                title={
                  confirmedOrder.mensagemCancelamento !== ""
                    ? "Cancelado"
                    : "Em Produção"
                }
                description={
                  confirmedOrder.mensagemCancelamento !== ""
                    ? confirmedOrder.mensagemCancelamento
                    : confirmedOrder?.updateTime.ACEITO
                    ? moment(confirmedOrder?.updateTime.ACEITO).format(
                        "DD/MM/YY - HH:mm:ss"
                      )
                    : "---"
                }
              />
              <Steps.Step
                title={
                  confirmedOrder?.entrega === ""
                    ? "Pronto para Retirar"
                    : "Saiu para Entrega"
                }
                description={
                  confirmedOrder?.updateTime.ENTREGA
                    ? moment(confirmedOrder?.updateTime.ENTREGA).format(
                        "DD/MM/YY - HH:mm:ss"
                      )
                    : "---"
                }
              />
              <Steps.Step
                title="Concluído"
                description={
                  confirmedOrder?.updateTime.CONCLUIDO
                    ? moment(confirmedOrder?.updateTime.CONCLUIDO).format(
                        "DD/MM/YY - HH:mm:ss"
                      )
                    : "---"
                }
              />
            </Steps>
          </div>
        )}
      </div>
      {config?.theme?.espera && config?.theme?.espera > 0 && !tipo && (
        <div
          className="p-shadow-9"
          style={{
            border: "2px solid white",
            padding: "0.5rem",
            margin: "0.1rem 1rem 0 1rem",
            minHeight: "50px",
            backgroundColor: "orange",
            color: "black",
            borderRadius: "10px",
            fontWeight: "bold",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {`TEMPO MÉDIO DE ESPERA (${config?.theme?.espera} min.)`}
        </div>
      )}
      {config?.theme?.qrcode && (
        <div
          className="p-shadow-9"
          style={{
            border: "2px solid white",
            padding: "1rem",
            margin: "1rem 1rem 0 1rem",
            minHeight: "100px",
            backgroundColor: "orange",
            color: "black",
            borderRadius: "10px",
            fontWeight: "bold",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          PEDIDO REALIZADO COM SUCESSO!
        </div>
      )}
      {confirmedOrder.formapagamento === "PIX" && opt.pixchave && (
        <div
          style={{
            padding: "10px",
            //margin: "8px",
            border: "1px solid white",
            borderRadius: "10px",
            backgroundColor: "black",
            color: "white",
            height: "60px",
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "20px",
            margin: "10px 15px",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "-11px",
              backgroundColor: "white",
              color: "black",
              border: "1px solid white",
              borderRadius: "10px",
              padding: "2px 10px",
              fontSize: "12px",
            }}
            onClick={() => {
              navigator.clipboard.writeText(opt.pixchave);
              toast.current.show({
                severity: "success",
                summary: "Pix copiado!",
                detail: "",
              });
            }}
          >
            Chave Pix (clique para copiar)
          </div>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigator.clipboard.writeText(opt.pixchave);
              toast.current.show({
                severity: "success",
                summary: "Pix copiado!",
                detail: "",
              });
            }}
          >
            {opt.pixchave}
          </div>
        </div>
      )}
      {confirmedOrder.formapagamento === "PIX" && opt.pixchave && (
        <a
          href={`https://api.whatsapp.com/send?phone=${opt.pixfone}`}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            border: "2px solid white",
            padding: "1rem",
            margin: "1rem",
            backgroundColor: "green",
            color: "white",
            borderRadius: "10px",
            display: !mesa ? "block" : "none",
            fontWeight: "bold",
            textAlign: "center",
          }}
          className="p-shadow-9"
        >
          Clique para abrir o Whatsapp e enviar o comprovante do pix!
        </a>
      )}
      {!tipo && (
        <div
          className="p-shadow-9"
          style={{
            border: "2px solid white",
            padding: "1rem",
            margin: "1rem 1rem 0 1rem",
            backgroundColor: "#323232",
            color: "white",
            borderRadius: "10px",
            fontWeight: "bold",
          }}
        >
          {confirmedOrder.itens.map((item, index) => {
            return (
              <div
                key={index}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div style={{ fontSize: "0.8rem" }}>
                  {item.quantidade} X {item.produto.descricao}
                </div>
                <div style={{ fontSize: "0.8rem" }}>
                  R$ {item.valortotal.toFixed(2)}
                </div>
              </div>
            );
          })}
          <Separator />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              fontSize: "0.8rem",
            }}
          >
            <div
              style={{ width: "70%", textAlign: "right", fontSize: "0.8rem" }}
            >
              TOTAL PRODUTOS:
            </div>
            <div style={{ fontSize: "0.8rem" }}>
              R$ {confirmedOrder.totalprodutos.toFixed(2)}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              fontSize: "0.8rem",
            }}
          >
            <div style={{ width: "70%", textAlign: "right" }}>
              TOTAL ENTREGA:
            </div>
            <div>R$ {confirmedOrder.valorentrega.toFixed(2)}</div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{ width: "70%", textAlign: "right", fontSize: "0.8rem" }}
            >
              TOTAL PEDIDO:
            </div>
            <div style={{ fontSize: "0.8rem" }}>
              R$ {confirmedOrder.totalpedido.toFixed(2)}
            </div>
          </div>
        </div>
      )}
      <div style={{ padding: "1rem" }}>
        <Button
          label={config?.theme?.qrcode ? "OK" : "VOLTAR AO CARDÁPIO"}
          style={{
            width: "100%",
            backgroundColor: config?.theme?.qrcode ? "green" : "orange",
            color: config?.theme?.qrcode ? "white" : "black",
            fontWeight: "bold",
            border: "2px solid white",
            borderRadius: "10px",
          }}
          onClick={onGoBack}
        />
      </div>
    </>
  );
};
