import React, { useContext } from "react";
import { Button } from "primereact/button";
import { useHistory } from "react-router-dom";
import { MainContext } from "../../Contexts/MainContext";

export const RegisterButton = () => {
  const history = useHistory();
  const { slug } = useContext(MainContext);

  function onRegister() {
    history.push(`/${slug}/login`);
  }

  return (
    <Button
      onClick={onRegister}
      style={{
        width: "100%",
        justifyContent: "center",
        fontWeight: "bold",
        backgroundColor: "#323232",
        color: "orange",
      }}
    >
      LOGAR PARA FINALIZAR O PEDIDO
    </Button>
  );
};
