import React, { useState, useEffect, useCallback } from "react";
import { InputNumber } from "primereact/inputnumber";
import { InputSwitch } from "primereact/inputswitch";

export const ComplementoCard = (props) => {
  const { produto, onComplementoChange, onValidate, cardapio } = props;
  const [complemento, setComplemento] = useState([]);
  const [agrupationArray, setAgrupationArray] = useState([]);
  const [validation, setValidation] = useState({});

  const styles = {
    complementoStyle: {
      border: "2px solid orange",
      marginLeft: "12px",
      marginRight: "12px",
      paddingLeft: "15px",
      color: "white",
      fontWeight: "bold",
      backgroundColor: "#323232",
    },

    errorStyle: {
      backgroundColor: "orange",
      marginLeft: "4rem",
      marginRight: "12px",
      textAlign: "center",
      height: "25px",
      justifyContent: "center",
      borderBottomRightRadius: "10px",
      borderBottomLeftRadius: "10px",
      animation: "cartAnimation 2s infinite",
    },
    adicionais: {
      backgroundColor: "orange",
      marginLeft: "12px",
      textAlign: "left",
      width: "80vw",
      borderTopRightRadius: "10px",
      marginTop: "3vh",
      flexDirection: "row",
      display: "flex",
      padding: "0 10px 0 10px",
      justifyContent: "space-between",
    },
  };

  useEffect(() => {
    let ok = false;

    if (validation) {
      Object.values(validation).forEach((value) => {
        if (value !== undefined) {
          ok = true;
        }
      });
    } else {
      ok = true;
    }

    onValidate(ok);
  }, [validation, onValidate]);

  function compare(a, b) {
    if (a.agrupador > b.agrupador) {
      return 1;
    }
    if (a.agrupador < b.agrupador) {
      return -1;
    }
    return 0;
  }

  const validate = useCallback(
    (newComplemento) => {
      agrupationArray.forEach(async (item) => {
        let iQuant = await newComplemento
          .filter((cp) => cp.agrupador === item.agrupador)
          .reduce((acc, item) => {
            return (acc += item.quantidade);
          }, 0);

        setValidation((old) => ({
          ...old,
          [item.agrupador]: getErrorMessage(iQuant, item),
        }));
      });
    },
    [agrupationArray]
  );

  useEffect(() => {
    validate(complemento);
  }, [complemento, validate]);

  useEffect(() => {
    let agrupador = [];
    let localAgrupationArray = produto.complemento.flatMap((item) => {
      if (agrupador.indexOf(item.agrupador) === -1) {
        agrupador.push(item.agrupador);
        return {
          agrupador: item.agrupador,
          minimo: item.minimo,
          maximo: item.maximo,
        };
      } else return [];
    });

    setAgrupationArray(localAgrupationArray);
    setComplemento(produto.complemento);
  }, [produto.complemento]);

  const getErrorMessage = (quantidade, item) => {
    if (item.minimo === 0 && item.maximo === 0) return undefined;
    if (quantidade < item.minimo) return "  Quantidade minima não atingida!  ";
    if (quantidade > item.maximo) return "  Quantidade maior que a permitida!";
    if (item.minimo === 0) return undefined;
  };

  async function changeOpcoes(index, value) {
    let newArray = [...complemento];

    newArray[index] = { ...newArray[index], quantidade: value.value };
    setComplemento(newArray);

    onComplementoChange(newArray);

    await validate(newArray);
  }

  async function changeOpcoesCK(index, value) {
    let newArray = [...complemento];

    newArray[index] = { ...newArray[index], quantidade: value.value ? 1 : 0 };
    setComplemento(newArray);

    onComplementoChange(newArray);

    await validate(newArray);
  }

  return (
    <>
      {agrupationArray.sort(compare).map((agrupador, key) => {
        const checkeBox = agrupador.agrupador.includes("?");
        return (
          <div
            key={key}
            className="p-grid p-dir-col"
            style={{ marginLeft: "1px", marginRight: "1px" }}
          >
            <div style={styles.adicionais}>
              <div>
                {agrupador.agrupador
                  ? agrupador.agrupador.includes("#")
                    ? `  ${agrupador.agrupador.split("#")[1].replace("?", "")}`
                    : `  ${agrupador.agrupador.replace("?", "")}`
                  : "Adicionais"}
              </div>
            </div>
            <div className="p-col p-shadow-7" style={styles.complementoStyle}>
              {complemento
                .filter((item) => item.agrupador === agrupador.agrupador)
                .map((cp, index) => (
                  <div
                    className="p-grid"
                    key={index}
                    style={{ paddingRight: "8px" }}
                  >
                    <div
                      className="p-col-9 p-col-align-center"
                      style={{ fontSize: "13px" }}
                    >
                      {cp.descricao}{" "}
                      {cp.preco > 0 ? `(R$ ${cp.preco.toFixed(2)})` : ""}
                    </div>
                    <div
                      className="p-col-3 p-col-align-end"
                      style={{ textAlignLast: "right" }}
                    >
                      {!cardapio &&
                        (checkeBox ? (
                          <InputSwitch
                            checked={cp.quantidade === 1}
                            onChange={(e) =>
                              changeOpcoesCK(
                                complemento.findIndex((x) => x.$id === cp.$id),
                                e
                              )
                            }
                          />
                        ) : (
                          <InputNumber
                            inputStyle={{ width: "35px", textAlign: "center" }}
                            id="horizontal"
                            value={cp.quantidade}
                            onValueChange={(e) =>
                              changeOpcoes(
                                complemento.findIndex((x) => x.$id === cp.$id),
                                e
                              )
                            }
                            showButtons
                            buttonLayout="horizontal"
                            step={1}
                            incrementButtonIcon="pi pi-plus"
                            decrementButtonIcon="pi pi-minus"
                            min={0}
                            mode="decimal"
                            readOnly
                          />
                        ))}
                    </div>
                  </div>
                ))}
            </div>
            {agrupador.agrupador !== "" && validation[agrupador.agrupador] && (
              <span style={styles.errorStyle}>
                {validation[agrupador.agrupador]}
              </span>
            )}
          </div>
        );
      })}
    </>
  );
};
