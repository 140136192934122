import React from "react";

export const Footer = (props) => {
  const style = {
    backgroundColor: "#323232",
    position: "fixed",
    transform: "translate3d(0,0,0)",
    left: "0",
    bottom: "0",
    height: "60px",
    width: "100%",
    boxShadow: "0px -8px 6px 1px rgba(0,0,0,0.2)",
    ...props.style,
  };

  const phantom = {
    display: "block",
    padding: "20px",
    height: "60px",
    width: "100%",
  };

  return (
    <div>
      <div style={phantom} />
      <div style={style}>{props.children}</div>
    </div>
  );
};
