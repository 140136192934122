import React, { useContext, useEffect, useState } from "react";
import { Button } from "primereact/button";
import { MainContext } from "../../Contexts/MainContext";
import { useHistory } from "react-router-dom";

export const CartItens = ({ item, config, index }) => {
  const { removeCartItem, setEditProduct, slug, desativados } =
    useContext(MainContext);
  const history = useHistory();
  const [sabores, setSabores] = useState("");

  useEffect(() => {
    let sabor = [];

    item.produto.complemento
      .filter((item) => item.tipo === "tiSabor" && item.quantidade > 0)
      .forEach((item2) => sabor.push(item2.descricao));

    setSabores(sabor.join(", "));
  }, [item]);

  const cartItenStyle = {
    marginBottom: "5px",
    marginTop: "5px",
    marginLeft: "2px",
    marginRight: "2px",
    maxHeight: "150px",
    backgroundColor: "#323232",
    borderRadius: "10px",
    color: desativados.includes(item.produto.descricao) ? "red" : "white",
    fontweight: "bold",
    border: "1px solid #656565",
  };

  const buttonStyle = {
    height: "2rem",
    color: "white",
    backgroundColor: "#323232",
    border: "0",
  };

  function deleteItem(index) {
    removeCartItem(index);
  }

  function editItem() {
    setEditProduct({
      type: "edit",
      index: index,
      produto: item.produto,
      itemMeia: item.meia,
      quantidade: item.quantidade,
    });

    if (item.produto.pizza) {
      history.push(`/${slug}/pizza`);
    } else {
      history.push(`/${slug}/product`);
    }
  }

  function InfoPizza() {
    if (item.produto.pizza) {
      return (
        <>
          <div
            style={{
              backgroundColor: "rgba(225,255,255,0.4)",
              width: "100%",
              height: "1px",
              margin: "0px 5px",
            }}
          />
          <div
            style={{
              fontweight: "bold",
              color: "bisque",
              fontSize: "10px",
              paddingTop: "0",
              marginLeft: "0.5rem",
              marginBottom: "0.5rem",
            }}
          >
            {sabores}
          </div>
        </>
      );
    } else {
      return <div></div>;
    }
  }

  return (
    <div className="p-grid p-justify-between p-shadow-9" style={cartItenStyle}>
      <div style={{ fontSize: "12px" }} className="p-col-5 p-col-align-center">
        {item.produto.descricao}
      </div>
      <div style={{ fontSize: "12px" }} className="p-col-1 p-col-align-center">
        {item.quantidade}
      </div>
      <div
        style={{ fontSize: "12px", textAlign: "end" }}
        className="p-col-3 p-col-align-center"
      >
        R$ {item.valortotal.toFixed(2)}
      </div>
      <Button
        icon="pi pi-pencil"
        aria-controls="popup-menu"
        aria-haspopup
        style={buttonStyle}
        className="p-col-1  p-col-align-center"
        onClick={editItem}
      />
      <Button
        icon="pi pi-trash"
        aria-controls="popup-menu"
        aria-haspopup
        style={buttonStyle}
        className="p-col-1  p-col-align-center"
        onClick={() => deleteItem(index)}
      />
      <InfoPizza />
    </div>
  );
};
