import axios from "axios";

async function confirmaPedido(pedido) {
  try {
    let resp = await axios.post("/pedidoservice/confirmapedido", pedido, {
      headers: { "xdata-serialize-instance-ref": "ifrecursive" },
    });

    if (resp.data.id === 0) {
      return {
        statusText:
          "Ocorreu um erro de conexão, favor atualizar a página e tentar novamente!",
        status: "401",
      };
    }

    return resp;
  } catch (e) {
    const { response } = e;
    console.log(e);

    if (response) {
      return response;
    } else {
      return { statusText: "Erro de conexão", status: "400" };
    }
  }
}

async function verificaStatus(id) {
  try {
    let oID = {
      id,
    };

    let resp = await axios.post("/pedidoservice/getstatus", oID);
    return resp;
  } catch (e) {
    const { response } = e;
    console.log(e);

    if (response) {
      return response;
    } else {
      return { statusText: "Erro de conexão", status: "400" };
    }
  }
}

export { confirmaPedido, verificaStatus };
