import React, { useState, useEffect } from "react";
import { InputSwitch } from "primereact/inputswitch";

export const ComposicaoCard = (props) => {
  const { config, produto, onComposicaoChange, cardapio } = props;
  const [composicao, setComposicao] = useState([]);

  useEffect(() => {
    setComposicao(produto.composicao);
  }, [produto.composicao]);

  const styles = {
    composicaoStyle: {
      border: "2px solid orange",
      marginLeft: "12px",
      marginRight: "12px",
      paddingLeft: "15px",
      paddingTop: "20px",
    },

    labelStyle: {
      backgroundColor: "orange",
      marginLeft: "12px",
      textAlign: "center",
      width: "20vh",
      borderTopRightRadius: "10px",
      marginTop: "1vh",
    },
    descricao: { color: config.theme.fontColor, fontWeight: "bold" },
  };

  function onLocalChangeComposicao(index, value) {
    let newArray = [...composicao];

    newArray[index] = { ...newArray[index], ativado: value.value };
    setComposicao(newArray);
    onComposicaoChange(newArray);
  }

  return (
    <div
      className="p-grid p-dir-col"
      style={{ marginLeft: "1px", marginRight: "1px" }}
    >
      <div style={styles.labelStyle}>Composição</div>
      <div className="p-col p-shadow-14" style={styles.composicaoStyle}>
        <div className="p-grid p-dir-col">
          {composicao.map((comp, index) => {
            return (
              <div
                key={index}
                className="p-grid p-justify-between"
                style={{ padding: "5px" }}
              >
                <div className="p-col-9" style={styles.descricao}>
                  {comp.descricao}
                </div>
                <div className="p-col-3 p-text-center">
                  {!cardapio && (
                    <InputSwitch
                      checked={comp.ativado}
                      onChange={(e) => onLocalChangeComposicao(index, e)}
                    />
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
