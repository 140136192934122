import React, { useContext } from "react";
import { Footer } from "../../Components/Footer";
import { Button } from "primereact/button";
import { MainContext } from "../../Contexts/MainContext";
import { RegisterButton } from "./RegisterButton";
import { useState } from "react";
import { useEffect } from "react";

export const FooterCart = ({
  config,
  onConfirmaPedido,
  enabled,
  order,
  frete,
}) => {
  const { user, payment, tipo, opt, addIndex } = useContext(MainContext);
  const [minimo, setMinimo] = useState(0);
  const [totalProducts, setTotalProducts] = useState(0);

  useEffect(() => {
    if (opt.minimo) {
      setMinimo(frete > 0 ? opt.minimo : 0);
    }
  }, [opt, frete]);

  useEffect(() => {
    let nTotalProduct = order.itens.reduce(
      (acc, item) => acc + item.valortotal,
      0
    );

    setTotalProducts(nTotalProduct);
  }, [order]);

  return (
    <Footer style={{ padding: "10px" }}>
      {user || tipo ? (
        <Button
          onClick={onConfirmaPedido}
          disabled={
            (!payment && !enabled) ||
            minimo > totalProducts ||
            (addIndex === -1 && !tipo)
          }
          style={{
            color: config.theme.fontColorSecondary,
            fontWeight: "bold",
            width: "100%",
            justifyContent: "center",
            backgroundColor: config.theme.secondary,
          }}
        >
          {minimo > 0 && minimo > totalProducts
            ? `Valor minimo não atingido (Falta: R$ ${(
                minimo - totalProducts
              ).toFixed(2)})`
            : "Finalizar Pedido"}
        </Button>
      ) : (
        <RegisterButton />
      )}
    </Footer>
  );
};
