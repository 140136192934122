import React, { useState, useEffect, useCallback } from "react";
import { InputNumber } from "primereact/inputnumber";
import "../Step/index.css";

export const ComplementoCard = (props) => {
  const {
    produto,
    onComplementoChange,
    onValidate,
    onValidatePizza,
    cardapio,
  } = props;
  const [complemento, setComplemento] = useState([]);
  const [saborArray, setSaborArray] = useState([]);
  const [agrupationArray, setAgrupationArray] = useState([]);
  const [validation, setValidation] = useState({});
  const { quantidadesabor } = produto;

  const styles = {
    errorStyle: {
      backgroundColor: "orange",
      marginLeft: "4rem",
      marginRight: "12px",
      textAlign: "center",
      height: "25px",
      justifyContent: "center",
      borderBottomRightRadius: "10px",
      borderBottomLeftRadius: "10px",
      animation: "step 1s infinite",
    },

    complementoStyle: {
      border: "2px solid orange",
      marginLeft: "12px",
      marginRight: "12px",
      paddingLeft: "15px",
      color: "white",
      fontWeight: "bold",
      backgroundColor: "#323232",
    },
    labelOpcionais: {
      backgroundColor: "orange",
      marginLeft: "12px",
      textAlign: "left",
      width: "80vw",
      borderTopRightRadius: "10px",
      marginTop: "3vh",
      flexDirection: "row",
      display: "flex",
      padding: "0 10px 0 10px",
      justifyContent: "space-between",
    },
  };

  useEffect(() => {
    let ok = false;

    if (validation) {
      Object.values(validation).forEach((value) => {
        if (value !== undefined) {
          ok = true;
        }
      });
    } else {
      ok = true;
    }

    onValidate(ok);
  }, [validation, onValidate]);

  const validatePizza = useCallback(
    (newComplemento) => {
      let qtdSabor = 0;
      newComplemento
        .filter((cp) => cp.tipo === "tiSabor")
        .forEach((item) => (qtdSabor += item.quantidade));

      if (qtdSabor > quantidadesabor || qtdSabor === 0) {
        onValidatePizza(true, qtdSabor > quantidadesabor);
      } else {
        onValidatePizza(false, false);
      }
    },
    [onValidatePizza, quantidadesabor]
  );

  const validate = useCallback(
    (newComplemento) => {
      agrupationArray.forEach(async (item) => {
        let iQuant = await newComplemento
          .filter(
            (cp) => cp.agrupador === item.agrupador && cp.tipo !== "tiSabor"
          )
          .reduce((acc, item) => {
            return (acc += item.quantidade);
          }, 0);

        setValidation((old) => ({
          ...old,
          [item.agrupador]: getErrorMessage(iQuant, item),
        }));
      });
    },
    [agrupationArray]
  );

  const getErrorMessage = (quantidade, item) => {
    if (item.minimo === 0 && item.maximo === 0) return undefined;
    if (quantidade < item.minimo) return "  Quantidade minima não atingida!  ";
    if (quantidade > item.maximo) return "  Quantidade maior que a permitida!";
    if (item.minimo === 0) return undefined;
  };

  useEffect(() => {
    validate(complemento);
    validatePizza(complemento);
  }, [complemento, validate, validatePizza]);

  useEffect(() => {
    let agrupador = [];
    let localAgrupationArray = produto.complemento
      .filter((item) => item.tipo === "tiSabor")
      .flatMap((item) => {
        if (agrupador.indexOf(item.agrupador) === -1) {
          agrupador.push(item.agrupador);
          return {
            agrupador: item.agrupador,
            minimo: item.minimo,
            maximo: item.maximo,
          };
        } else return [];
      });
    setSaborArray(localAgrupationArray);
    setComplemento(produto.complemento);
  }, [produto.complemento]);

  useEffect(() => {
    let agrupador = [];
    let localAgrupationArray = produto.complemento
      .filter((item) => item.tipo !== "tiSabor")
      .flatMap((item) => {
        if (agrupador.indexOf(item.agrupador) === -1) {
          agrupador.push(item.agrupador);
          return {
            agrupador: item.agrupador,
            minimo: item.minimo,
            maximo: item.maximo,
          };
        } else return [];
      });

    setAgrupationArray(localAgrupationArray);
    setComplemento(produto.complemento);
  }, [produto.complemento]);

  async function changeOpcoes(index, value) {
    let newArray = [...complemento];

    newArray[index] = { ...newArray[index], quantidade: value.value };
    setComplemento(newArray);

    onComplementoChange(newArray);

    validate(newArray);
    validatePizza(newArray);
  }

  return (
    <>
      {saborArray.map((agrupador, key) => (
        <div
          key={key}
          className="p-grid p-dir-col"
          style={{ marginLeft: "1px", marginRight: "1px" }}
        >
          <div style={styles.labelOpcionais}>
            <div>
              {agrupador.agrupador ? `  ${agrupador.agrupador}` : "Opcionais"}
            </div>
          </div>
          <div className="p-col p-shadow-7" style={styles.complementoStyle}>
            {complemento
              .filter((item) => item.agrupador === agrupador.agrupador)
              .map((cp, index) => (
                <div
                  className="p-grid"
                  style={{
                    marginTop: "0.5rem",
                    justifyContent: "space-between",
                    paddingRight: "8px",
                  }}
                  key={index}
                >
                  <div
                    className="p-col-9"
                    style={{ alignSelf: "center", fontSize: "13px" }}
                  >
                    <div className="p-grid p-dir-row">
                      <div className="p-col-12" style={{ padding: "0" }}>
                        {cp.descricao}{" "}
                        {cp.preco > 0 ? `(R$ ${cp.preco.toFixed(2)})` : ""}
                      </div>
                      <div style={{ fontSize: "10px", color: "bisque" }}>
                        {cp.detalhe}
                      </div>
                    </div>
                  </div>
                  <div className="p-col-3">
                    {!cardapio && (
                      <InputNumber
                        inputStyle={{ width: "35px", textAlign: "center" }}
                        id="horizontal"
                        value={cp.quantidade}
                        onValueChange={(e) =>
                          changeOpcoes(
                            complemento.findIndex((x) => x.$id === cp.$id),
                            e
                          )
                        }
                        showButtons
                        buttonLayout="horizontal"
                        step={1}
                        incrementButtonIcon="pi pi-plus"
                        decrementButtonIcon="pi pi-minus"
                        min={0}
                        mode="decimal"
                        readOnly
                      />
                    )}
                  </div>
                </div>
              ))}
          </div>
        </div>
      ))}
      {agrupationArray
        .sort((a, b) => (a.agrupador < b.agrupador ? 1 : -1))
        .map((agrupador, key) => (
          <div
            key={key}
            className="p-grid p-dir-col"
            style={{ marginLeft: "1px", marginRight: "1px" }}
          >
            <div style={styles.labelOpcionais}>
              <div>
                {agrupador.agrupador ? `  ${agrupador.agrupador}` : "Opcionais"}
              </div>
            </div>
            <div className="p-col p-shadow-7" style={styles.complementoStyle}>
              {complemento
                .filter((item) => item.agrupador === agrupador.agrupador)
                .map((cp, index) => (
                  <div
                    className="p-grid"
                    style={{
                      marginTop: "0.5rem",
                      justifyContent: "space-between",
                      paddingRight: "8px",
                    }}
                    key={index}
                  >
                    <div
                      className="p-col-9"
                      style={{ alignSelf: "center", fontSize: "13px" }}
                    >
                      <div className="p-grid p-dir-row">
                        <div className="p-col-12" style={{ padding: "0" }}>
                          {cp.descricao}{" "}
                          {cp.preco > 0 ? `(R$ ${cp.preco.toFixed(2)})` : ""}
                        </div>
                        <div style={{ fontSize: "10px", color: "bisque" }}>
                          {cp.detalhe}
                        </div>
                      </div>
                    </div>
                    <div className="p-col-3">
                      {!cardapio && (
                        <InputNumber
                          inputStyle={{ width: "35px", textAlign: "center" }}
                          id="horizontal"
                          value={cp.quantidade}
                          onValueChange={(e) =>
                            changeOpcoes(
                              complemento.findIndex((x) => x.$id === cp.$id),
                              e
                            )
                          }
                          showButtons
                          buttonLayout="horizontal"
                          step={1}
                          incrementButtonIcon="pi pi-plus"
                          decrementButtonIcon="pi pi-minus"
                          min={0}
                          mode="decimal"
                          readOnly
                        />
                      )}
                    </div>
                  </div>
                ))}
            </div>
            {agrupador.agrupador !== "" && validation[agrupador.agrupador] && (
              <span style={styles.errorStyle}>
                {validation[agrupador.agrupador]}
              </span>
            )}
          </div>
        ))}
    </>
  );
};
