import React, { useContext } from "react";
import { MainContext } from "../../Contexts/MainContext";
import { AddressSelect } from './AddressSelect'

export const AddressCard = ({ config }) => {
  const { user } = useContext(MainContext);

  const addressCardStyle = {
    background: config.theme.secondary,
    paddingTop: "0.5rem",
    paddingLeft: "1.2rem",
    paddingRight: "1.2rem",
    paddingBottom: "0.9rem",
    margin: "0.5rem",
    marginTop: "0.5rem",
    marginBottom: "1.5rem",
    borderRadius: "10px",
  };


  return (
    <div style={addressCardStyle} className="p-shadow-9">
      <AddressSelect user={user} />
    </div>
  );
};
