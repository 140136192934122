import React, { useEffect, useState } from "react";
import { Separator } from "../../Components/Separator";
import { CartSummaryItem } from "./CartSummaryItem";

export const CartSummary = ({ order, config, frete }) => {
  const [total, setTotal] = useState(23.33);
  const [totalProducts, setTotalProducts] = useState(0);

  useEffect(() => {
    let nTotalProduct = order?.itens?.reduce(
      (acc, item) => acc + item.valortotal,
      0
    );

    setTotalProducts(nTotalProduct);
    setTotal(nTotalProduct + frete);
  }, [order, frete]);

  return (
    <div
      className="p-grid p-dir-col p-shadow-9"
      style={{
        flexWrap: "nowrap",
        marginLeft: "0.5rem",
        marginRight: "0.5rem",
        borderRadius: "10px",
        marginTop: "0.5rem",
        marginBottom: "0.5rem",
        padding: "0.8rem",
        backgroundColor: "#323232",
        color: "white",
        border: "1px solid white",
      }}
    >
      {!config.theme.qrcode && (
        <CartSummaryItem
          config={config}
          title="TOTAL PRODUTOS:"
          value={totalProducts}
          style={{ marginTop: "5px" }}
        />
      )}
      {frete > 0 && (
        <CartSummaryItem config={config} title="TOTAL ENTREGA:" value={frete} />
      )}
      {!config.theme.qrcode && <Separator />}
      {config.theme.qrcode ? (
        <CartSummaryItem
          config={config}
          title="TOTAL DESTE PEDIDO:"
          value={total}
        />
      ) : (
        <CartSummaryItem config={config} title="TOTAL PEDIDO:" value={total} />
      )}
    </div>
  );
};
