import React from "react";
import { InputTextarea } from "primereact/inputtextarea";

export const ObservacaoCard = (props) => {
  const { observacao, config, onChange } = props;

  const styles = {
    obsStyle: {
      marginLeft: "13px",
      marginRight: "13px",
      marginTop: "10px",
      minHeight: "100px",
      background: "#323232",
      border: `2px solid ${config.theme.secondary}`,
      color: "white",
    },
  };

  return (
    <InputTextarea
      className="p-col p-shadow-9"
      placeholder="Alguma Observação?"
      autoResize
      style={styles.obsStyle}
      value={observacao}
      onChange={onChange}
    />
  );
};
